import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Input, OnInit } from '@angular/core';
import { FormControl, ReactiveFormsModule, Validators } from '@angular/forms';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { NzButtonModule } from 'ng-zorro-antd/button';
import { NzInputModule } from 'ng-zorro-antd/input';
import { NzSpinModule } from 'ng-zorro-antd/spin';
import { BehaviorSubject, finalize, switchMap, tap } from 'rxjs';

import { JobCommentApiService } from '@api/job-comment/job-comment-api.service';
import { JobCommentResource } from '@api/job-comment/types';
import { trackById } from '@shared/utils/directives/track-by-id';
import { JobCommentComponent } from '../job-comment/job-comment.component';
import { MAX_COMMENT_LENGTH } from '../constants';

@UntilDestroy()
@Component({
    selector: 'un-job-comments',
    standalone: true,
    imports: [CommonModule, ReactiveFormsModule, NzSpinModule, JobCommentComponent, NzButtonModule, NzInputModule],
    templateUrl: './job-comments.component.html',
    styleUrls: ['./job-comments.component.less'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    providers: [JobCommentApiService],
})
export class JobCommentsComponent implements OnInit {
    readonly trackById = trackById;

    @Input()
    jobId?: string;

    @Input()
    comments: JobCommentResource[] = [];

    commentCtrl = new FormControl('', [Validators.required, Validators.maxLength(MAX_COMMENT_LENGTH)]);
    commentsLoading$ = new BehaviorSubject(false);

    constructor(private readonly commentsApi: JobCommentApiService, private readonly cdr: ChangeDetectorRef) {}

    ngOnInit(): void {
        if (this.jobId != null) {
            this.commentsLoading$.next(true);
            this.fetchComments(this.jobId).subscribe();
        }
    }

    createComment(): void {
        const jobId = this.jobId;

        if (jobId != null && this.commentCtrl.value) {
            this.commentsLoading$.next(true);

            this.commentsApi
                .create(jobId, this.commentCtrl.value)
                .pipe(
                    switchMap(() => {
                        this.commentCtrl.reset();

                        return this.fetchComments(jobId);
                    }),
                )
                .subscribe();
        }
    }

    onDeleteComment(commentId: string): void {
        const jobId = this.jobId;

        if (jobId != null) {
            this.commentsLoading$.next(true);

            this.commentsApi
                .remove(jobId, commentId)
                .pipe(
                    switchMap(() => this.fetchComments(jobId)),
                    untilDestroyed(this),
                )
                .subscribe();
        }
    }

    private fetchComments(jobId: string) {
        return this.commentsApi.getList(jobId).pipe(
            tap((data) => {
                this.comments = data?.content ?? [];
                this.cdr.markForCheck();
            }),
            finalize(() => {
                this.commentsLoading$.next(false);
            }),
        );
    }
}
