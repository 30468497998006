import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, inject, Input } from '@angular/core';

import { FileService } from '@app/core/services/file.service';

@Component({
    selector: 'un-image-view[imageId]',
    template: `<img *ngIf="url" [src]="url" />`,
    standalone: true,
    imports: [CommonModule],
    styleUrls: ['./image-view.component.less'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ImageViewComponent {
    url?: string;
    fileService = inject(FileService);

    @Input()
    set imageId(val: string | undefined) {
        this.url = val ? this.fileService.getFileUrl(val) : undefined;
    }
}
