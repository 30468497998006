import { ChangeDetectionStrategy, Component, forwardRef } from '@angular/core';
import { NG_VALUE_ACCESSOR, NonNullableFormBuilder, Validators } from '@angular/forms';
import { trackBySelectItem } from '@shared/utils/directives/track-by-select-item';
import { ScheduleType } from '@un-types/schedule/schedule-type';
import { TimePeriod } from '@un-types/time-period';
import { SCHEDULE_TYPE_OPTIONS } from './constants/schedule-type-options';
import { TIME_PERIOD_OPTIONS } from './constants/time-period-options';
import { ScheduleChangesCallback } from './types/schedule-changes-callback';

@Component({
    selector: 'un-schedule-editor',
    templateUrl: './schedule-editor.component.html',
    styleUrls: ['./schedule-editor.component.less'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            useExisting: forwardRef(() => ScheduleEditorComponent),
            multi: true,
        },
    ],
})
export class ScheduleEditorComponent {
    readonly type = this.formBuilder.control<ScheduleType>(ScheduleType.Discrete);

    readonly workTime = this.formBuilder.group({
        count: this.formBuilder.control(0, [Validators.min(0)]),
        period: this.formBuilder.control(TimePeriod.Day),
    });

    readonly discrete = this.formBuilder.group({
        frequency: this.formBuilder.control(0, [Validators.min(0)]),
        period: this.formBuilder.control(TimePeriod.Day),
        workTime: this.workTime,
    });

    readonly exact = this.formBuilder.group({
        dates: this.formBuilder.array([]),
        workTime: this.workTime,
    });

    readonly scheduleType = ScheduleType;
    readonly timePeriodOptions = TIME_PERIOD_OPTIONS;
    readonly scheduleTypeOptions = SCHEDULE_TYPE_OPTIONS;
    readonly trackBySelectItem = trackBySelectItem;

    onChanges?: ScheduleChangesCallback;
    onTouched?: () => void;

    constructor(private readonly formBuilder: NonNullableFormBuilder) {}

    writeValue(): void {
        // do nothing
    }

    registerOnChange(onChanges: ScheduleChangesCallback): void {
        this.onChanges = onChanges;
    }

    registerOnTouched(onTouched: () => void): void {
        this.onTouched = onTouched;
    }

    onChangeScheduleType(index: number): void {
        const type = this.scheduleTypeOptions[index].value as ScheduleType;

        this.type.setValue(type);
    }
}
