import { Links } from '@api/types';
import { FullUserResource } from '@api/user/types';

export interface Notification {
    id: string;
    jobId: string;
    statusName: string;
    jobName: string;
    createdAt: string; // date-time ISO
    jobCreator: JobCreator;
    type: NotificationType;
}

export interface JobCreator extends FullUserResource {
    _links: Links;
}

export enum NotificationType {
    Create = 'JOB_CREATE',
    Update = 'JOB_STATUS_UPDATE',
}
