import { UserPermission } from '@app/core/types';

export const UniodCtlr = 'uniod';
export const UniodPermissions = {
    create: [UniodCtlr, 'create'] as UserPermission,
    getOne: [UniodCtlr, 'getOne'] as UserPermission,
    search: [UniodCtlr, 'search'] as UserPermission,
    update: [UniodCtlr, 'update'] as UserPermission,
    delete: [UniodCtlr, 'delete'] as UserPermission,
} as const;

export const UniodMapCtlr = 'uniodMap';
export const UniodMapPermissions = {
    create: [UniodMapCtlr, 'create'] as UserPermission,
    search: [UniodMapCtlr, 'search'] as UserPermission,
    update: [UniodMapCtlr, 'update'] as UserPermission,
} as const;
