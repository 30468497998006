import { Route } from '@angular/router';

import { CompanyPermissions, GanttPermissions, JobPermissions, UniodModelPermissions } from './core/permissions';
import { ProtectedRoute } from './core/types';
import { UserPermissions } from './modules/users/permissions';
import { UniodPermissions } from './modules/facilities/permissions';

export const FacilityRoute: ProtectedRoute = {
    path: 'facilities',
    data: { permissions: [UniodPermissions.search, UniodPermissions.getOne] },
} as const;

export const UsersRoute: ProtectedRoute = {
    path: 'users',
    data: { permissions: [UserPermissions.search, UserPermissions.getOne] },
} as const;

export const MonitoringRoute: ProtectedRoute = {
    path: 'monitoring',
    data: { permissions: [UniodPermissions.search, UniodPermissions.getOne] },
} as const;

export const JournalRoute: ProtectedRoute = {
    path: 'journal',
    data: { permissions: [GanttPermissions.get] },
} as const;

export const JobsRoute: ProtectedRoute = {
    path: 'jobs',
    data: { permissions: [JobPermissions.search, JobPermissions.getOne] },
} as const;

export const ModelsRoute: ProtectedRoute = {
    path: 'models',
    data: { permissions: [UniodModelPermissions.search, UniodModelPermissions.getOne] },
} as const;

export const UniodRoute: ProtectedRoute = {
    path: 'uniod',
    data: { permissions: [UniodPermissions.search, UniodPermissions.getOne] },
} as const;

export const AdministrationRoute: ProtectedRoute = {
    path: 'administration',
    data: {
        permissions: [
            CompanyPermissions.update,
            CompanyPermissions.updateModules,
            CompanyPermissions.excludeServiceCompany,
            CompanyPermissions.includeServiceCompany,
        ],
    },
} as const;

export const FacilityGroupsRoute: ProtectedRoute = {
    path: 'facility-groups',
    data: { permissions: [UniodPermissions.search, UniodPermissions.getOne] },
} as const;

export const ForbiddenRoute: Route = {
    path: 'no-access',
};

export const RootRoutes = [
    JournalRoute,
    MonitoringRoute,
    JobsRoute,
    FacilityGroupsRoute,
    FacilityRoute,
    ModelsRoute,
    UsersRoute,
    AdministrationRoute,
];
