import { ScheduleType } from '@un-types/schedule/schedule-type';
import { NzNormalizedOptions } from 'ng-zorro-antd/segmented';

export const SCHEDULE_TYPE_OPTIONS: NzNormalizedOptions = [
    {
        label: 'Дискретность',
        value: ScheduleType.Discrete,
    },
    {
        label: 'Точные даты',
        value: ScheduleType.Exact,
    },
];
