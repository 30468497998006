import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ResizableSidebarService } from '@app/services/resizable-sidebar/resizable-sidebar.service';
import { ScrollableBodyService } from '@services/scrollable-body/scrollable-body.service';
import { ContentWrapperComponent } from './content-wrapper.component';
import { LeftSidebarDirective } from './directive/left-sidebar.directive';
import { RightSidebarDirective } from './directive/right-sidebar.directive';

@NgModule({
    declarations: [ContentWrapperComponent, LeftSidebarDirective, RightSidebarDirective],
    imports: [CommonModule],
    exports: [ContentWrapperComponent, LeftSidebarDirective],
    providers: [ScrollableBodyService, ResizableSidebarService],
})
export class ContentWrapperModule {}
