import {
    AfterViewInit,
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    ElementRef,
    ViewChild,
} from '@angular/core';

@Component({
    selector: 'un-form-item',
    templateUrl: './form-item.component.html',
    styleUrls: ['./form-item.component.less'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FormItemComponent implements AfterViewInit {
    @ViewChild('itemLabelWrapper')
    private readonly itemLabelWrapper!: ElementRef;

    @ViewChild('itemDescriptionWrapper')
    private readonly itemDescriptionWrapper!: ElementRef;

    showItemLabel = false;
    showItemDescription = false;

    constructor(private readonly changeDetectorRef: ChangeDetectorRef) {}

    ngAfterViewInit(): void {
        this.showItemLabel = this.itemLabelWrapper.nativeElement?.children?.length > 0;
        this.showItemDescription = this.itemDescriptionWrapper.nativeElement?.children?.length > 0;

        this.changeDetectorRef.detectChanges();
    }
}
