import { HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { NzNotificationService } from 'ng-zorro-antd/notification';
import { Observable, catchError, throwError } from 'rxjs';

import { ProfileService } from '../services/profile.service';

const Http401 = 401;
const Http403 = 403;

@Injectable({ providedIn: 'root' })
export class ErrorInterceptor implements HttpInterceptor {
    constructor(
        private readonly profileService: ProfileService,
        private readonly nzNotificationService: NzNotificationService, // private readonly router: Router,
    ) {}

    intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
        return next.handle(request).pipe(catchError((res) => this.errorHandler(request, res)));
    }

    private errorHandler(request: HttpRequest<unknown>, response: HttpErrorResponse): Observable<never> {
        const status = response?.status;

        switch (status) {
            case Http401:
                if (!request.url.includes('logout')) {
                    this.profileService.logout();
                }

                break;
            case Http403:
                // this.router.navigate(['no-access']);
                break;
            default:
                break;
        }

        const errorText = status === Http403 ? 'Доступ запрещен' : `${response?.error?.detail}`;

        this.nzNotificationService.error('Ошибка', errorText);

        return throwError(() => response?.error);
    }
}
