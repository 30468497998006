import { Injectable } from '@angular/core';
import { type Observable } from 'rxjs';

import { ApiAbstract } from '@api/api-abstract';
import { DefaultPaginationParams } from '@api/constants';
import { Page, SearchParams } from '@api/types';
import { JobCommentResource } from './types';

@Injectable({ providedIn: 'root' })
export class JobCommentApiService extends ApiAbstract {
    constructor() {
        super('job');
    }

    getList(jobId: string, params?: SearchParams) {
        return this.httpClient.get<Page<JobCommentResource>>(`${this.base}/${jobId}/comment`, {
            params: { ...DefaultPaginationParams, ...params },
        });
    }

    create(jobId: string, text: string): Observable<JobCommentResource> {
        return this.httpClient.post<JobCommentResource>(`${this.base}/${jobId}/comment`, { text });
    }

    remove(jobId: string, commentId: string): Observable<void> {
        return this.httpClient.delete<void>(`${this.base}/${jobId}/comment/${commentId}`);
    }
}
