import { Injectable } from '@angular/core';
import { Observable, map, shareReplay } from 'rxjs';

import { ApiAbstract } from '@api/api-abstract';
import { LoginApiResp, LoginForm, RecoveryForm, RegisterForm, RememberMeRequest, ResetPasswordForm } from './types';

@Injectable({ providedIn: 'root' })
export class AuthApiService extends ApiAbstract {
    constructor() {
        super('auth');
    }

    login(loginData: LoginForm): Observable<LoginApiResp> {
        return this.httpClient.post<LoginApiResp>(`${this.base}/login`, loginData).pipe(shareReplay());
    }

    logout(): Observable<void> {
        return this.httpClient.post<void>(`${this.base}/logout`, undefined);
    }

    register(registerData: RegisterForm): Observable<boolean> {
        // TODO
        return this.httpClient.post(`${this.base}/register`, registerData).pipe(map((data) => !!data));
    }

    recovery(recoveryData: RecoveryForm): Observable<boolean> {
        return this.httpClient.post<void>(`${this.base}/reset-password`, recoveryData).pipe(map(() => true));
    }

    emailConfirm(token: string): Observable<unknown> {
        // TODO
        return this.httpClient.post<void>(`${this.base}/email-confirm/${token}`, {});
    }

    resetPassword(token: string, resetPasswordData: ResetPasswordForm): Observable<unknown> {
        // TODO
        return this.httpClient.post(`${this.base}/reset-password/${token}`, resetPasswordData);
    }

    acceptInvite(token: string, createPasswordData: ResetPasswordForm): Observable<unknown> {
        return this.httpClient.post(`${this.base}/accept-invite/${token}`, createPasswordData);
    }

    rememberMe(rememberMeData: RememberMeRequest): Observable<LoginApiResp> {
        return this.httpClient.post<LoginApiResp>(`${this.base}/remember-me`, rememberMeData);
    }
}
