import { ChangeDetectionStrategy, Component, Input } from '@angular/core';

@Component({
    selector: 'un-form-block',
    templateUrl: './form-block.component.html',
    styleUrls: ['./form-block.component.less'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FormBlockComponent {
    @Input()
    hasBorder = true;
}
