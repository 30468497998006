import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { ApiAbstract } from '@api/api-abstract';
import { Page, SearchParams } from '@api/types';
import {
    CreateUniodRequest,
    UniodGroupsList,
    UniodListItem,
    UniodResource,
    UpdateUniodCodeListRequest,
    UpdateUniodRequest,
} from '@api/uniod/types/uniod';
import { DefaultPaginationParams } from '../constants';
import { ListUniodRequest, SearchUniodRequest } from './types/request';

@Injectable({ providedIn: 'root' })
export class UniodApiService extends ApiAbstract {
    constructor() {
        super('uniod');
    }

    searchUniods(
        filter: Partial<SearchUniodRequest>,
        params: SearchParams = DefaultPaginationParams,
    ): Observable<Page<UniodResource>> {
        return this.httpClient.post<Page<UniodResource>>(`${this.base}/search`, filter, { params });
    }

    searchList(
        filter: Partial<ListUniodRequest>,
        params: SearchParams = DefaultPaginationParams,
    ): Observable<Page<UniodGroupsList>> {
        return this.httpClient.post<Page<UniodGroupsList>>(`${this.base}/search/list`, filter, { params });
    }

    list(params: SearchParams) {
        return this.httpClient.get<Page<UniodListItem>>(`${this.base}/list`, { params });
    }

    withChildrens(params: SearchParams = DefaultPaginationParams): Observable<Page<UniodGroupsList>> {
        return this.httpClient.get<Page<UniodGroupsList>>(`${this.base}/with-children`, { params });
    }

    getUniodById(id: string): Observable<UniodResource> {
        return this.httpClient.get<UniodResource>(`${this.base}/${id}`);
    }

    createUniod(uniodCreateData: CreateUniodRequest): Observable<UniodResource> {
        return this.httpClient.post<UniodResource>(`${this.base}`, uniodCreateData);
    }

    deleteUniod(id: string): Observable<void> {
        return this.httpClient.delete<void>(`${this.base}/${id}`);
    }

    updateUniod(id: string, uniodData: UpdateUniodRequest): Observable<UniodResource> {
        return this.httpClient.patch<UniodResource>(`${this.base}/${id}`, uniodData);
    }

    eventsCounter(id: string) {
        return this.httpClient.get<string>(`${this.base}/${id}/events`);
    }

    jobsCounter(id: string) {
        return this.httpClient.get<string>(`${this.base}/${id}/jobs`);
    }

    actualizeCodelist(codeListData: UpdateUniodCodeListRequest) {
        return this.httpClient.patch<void>(`${this.base}/codelist`, codeListData);
    }
}
