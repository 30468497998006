<ng-template [ngIf]="mode === 'edit'" [ngIfElse]="viewMode">
  <form nz-form [formGroup]="form">
    <table>
      <thead>
        <tr>
          <th class="check-list-input__point">№ пп</th>
          <th class="check-list-input__content">Содержимое работы</th>
        </tr>
      </thead>
      <tbody formArrayName="checkPoints">
        <ng-template ngFor let-ctrl let-index="index" [ngForOf]="form.controls.checkPoints.controls"
          [ngForTrackBy]="trackByIndex">
          <tr>
            <td class="check-list-input__point">{{index+1}}<button nz-button nzType="text" nzDanger><span nz-icon
                  nzType="delete" nzTheme="outline" title="Удалить пукт" (click)="removeItem(index)"></span></button>
            </td>
            <td class="check-list-input__content">
              <nz-form-control [formGroupName]="index">
                <textarea nz-input formControlName="label" [nzAutosize]="{minRows: 1, maxRows:5}"></textarea>
              </nz-form-control>
            </td>
          </tr>
        </ng-template>
        <tr>
          <td colspan="2" class="check-list-input__add-point-cell"><button nz-button nzType="primary"
              title="Добавить пункт" (click)="addItem()"><span nz-icon nzType="plus-circle" nzTheme="outline"></span>
              Добавить пункт</button></td>
      </tbody>
    </table>
  </form>
</ng-template>

<ng-template #viewMode>
  <form nz-form [formGroup]="form">
    <ng-template ngIf="checkPoints?.length">
      <nz-progress nzStrokeLinecap="round" [nzPercent]="progress"></nz-progress>
      <div class="check-list-input__list" formArrayName="checkPoints">
        <ng-template ngFor let-ctrl let-index="index" [ngForOf]="form.controls.checkPoints.controls"
          [ngForTrackBy]="trackByIndex">
          <ng-container [formGroupName]="index">
            <label class="check-list-input__list-item" nz-checkbox formControlName="value">
              {{ form.get(['checkPoints', index, 'label'])?.value }}
            </label>
          </ng-container>
        </ng-template>
      </div>
    </ng-template>
  </form>
</ng-template>