import { Injectable } from '@angular/core';
import { BehaviorSubject, take, tap } from 'rxjs';

import { ConfigApiService } from '@api/config/config-api.service';
import { AppConfig } from '@api/config/types';

@Injectable({ providedIn: 'root' })
export class AppConfigService {
    private readonly current = new BehaviorSubject<AppConfig | undefined>(undefined);
    readonly current$ = this.current.asObservable();

    constructor(private readonly configApi: ConfigApiService) {}
    getCurrent() {
        return this.configApi.getCurrent().pipe(
            tap((cfg) => {
                this.current.next(cfg);
            }),
        );
    }

    reset() {
        this.current.next(undefined);

        return this.current.pipe(take(1));
    }
}
