import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, Input, inject } from '@angular/core';
import { NzAvatarModule } from 'ng-zorro-antd/avatar';

import { UserResponseDto } from '@api/user/types';
import { FileService } from '@app/core/services/file.service';

type DefaultUserAvatar = 'profile' | 'user' | 'user-square' | 'detail';

@Component({
    selector: 'un-user-avatar[user]',
    standalone: true,
    imports: [CommonModule, NzAvatarModule],
    templateUrl: './user-avatar.component.html',
    styleUrls: ['./user-avatar.component.less'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class UserAvatarComponent {
    private readonly fileSrv = inject(FileService);

    @Input()
    size: 'large' | 'small' | 'default' | number = 'default';

    @Input()
    user: { photo: UserResponseDto['photo'] } | null = null;

    @Input()
    defaultAvatar?: DefaultUserAvatar = 'profile';

    get url(): string {
        const profileUrl = '/assets/images/components/avatar/mock-avatar.png';
        const userUrl = '/assets/images/components/avatar/mock-users-avatar.png';
        const squareUrl = '/assets/images/components/avatar/mock-square-avatar.png';
        const detailUrl = '/assets/images/components/avatar/mock-detail-avatar.png';

        if (this.user?.photo?.id) {
            return this.fileSrv.getFileUrl(this.user.photo.id);
        }

        switch (this.defaultAvatar) {
            case 'user':
                return userUrl;
            case 'user-square':
                return squareUrl;
            case 'detail':
                return detailUrl;
            default:
                return profileUrl;
        }
    }
}
