import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Input, forwardRef } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { NzButtonModule } from 'ng-zorro-antd/button';
import { NzIconModule } from 'ng-zorro-antd/icon';
import { NzUploadFile, NzUploadModule, NzUploadXHRArgs } from 'ng-zorro-antd/upload';
import { Subscription, catchError, finalize, of } from 'rxjs';

import { FileApiService } from '@api/file/file-api.service';
import { saveBlobToFile } from '@app/shared/utils/file';
import { FileChangesCallback } from './types/file-changes-callback';
import { FileService } from '@app/core/services/file.service';

const MAX_FILES_AMOUNT = 10;

@Component({
    selector: 'un-file-uploader-button',
    standalone: true,
    imports: [CommonModule, NzButtonModule, NzIconModule, NzUploadModule],
    templateUrl: './file-uploader-button.component.html',
    styleUrls: ['./file-uploader-button.component.less'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            useExisting: forwardRef(() => FileUploaderButtonComponent),
            multi: true,
        },
        FileApiService,
    ],
})
export class FileUploaderButtonComponent implements ControlValueAccessor {
    @Input()
    autoUpload = true;

    @Input()
    set maxFiles(value: string | number) {
        if (typeof value === 'string') {
            this.maxFilesAmount =
                parseInt(value) && parseInt(value) > 0 ? Math.trunc(parseInt(value)) : MAX_FILES_AMOUNT;
        }

        if (typeof value === 'number' && value > 0) {
            this.maxFilesAmount = Math.trunc(value);
        }
    }

    value: string[] = [];
    onChanges?: FileChangesCallback;
    onTouched?: () => void;
    // eslint-disable-next-line @typescript-eslint/no-empty-function
    onChanged: FileChangesCallback = () => {};

    fileList: NzUploadFile[] = [];
    fileIds = new Map<string, string>();
    disabled = false;
    maxFilesAmount = MAX_FILES_AMOUNT;

    loading = false;

    constructor(
        private readonly fileApi: FileApiService,
        private readonly fileSrv: FileService,
        private readonly cdr: ChangeDetectorRef,
    ) {}

    writeValue(value: string[] | string): void {
        if (value && typeof value === 'string') {
            this.value = [value];
            this.fileIds.set(value, value);
        }

        if (value && Array.isArray(value)) {
            this.value = [...value];

            this.value.forEach((item) => {
                this.fileIds.set(item, item);
            });
        }

        if (this.fileList.length === 0 && this.value.length > 0) {
            this.getMeta(this.value);
        }
    }

    registerOnChange(onChanges: FileChangesCallback): void {
        this.onChanges = onChanges;
        this.onChanged = onChanges;
    }

    registerOnTouched(onTouched: () => void): void {
        this.onTouched = onTouched;
    }

    setDisabledState(disabled: boolean): void {
        this.disabled = disabled;
    }

    onRemoveFile(event: Event, id?: string): void {
        event.stopPropagation();
        this.onTouched?.();

        if (!id) {
            this.fileList = [];
            this.fileIds.clear();
            this.onChanged([]);
            this.writeValue([]);
        } else {
            this.fileList = this.fileList.filter((item) => item.uid !== id);
            this.value = this.value.filter((item) => item !== this.fileIds.get(id));
            this.fileIds.delete(id);
            this.onChanged(this.value);
        }

        this.cdr.markForCheck();
    }

    beforeUpload = (file: NzUploadFile): boolean => {
        this.fileList = this.fileList.concat(file);

        this.onTouched?.();
        this.cdr.markForCheck();

        return this.autoUpload;
    };
    /*
    upload(): Observable<{ id: string } | undefindef> {
        if (this.file) {
            const blob = new Blob(this.file.originFileObj);
            return this.fileApi
                .upload(this.file.originFileObj, item?.file?.name || '')
                .pipe(
                    tap(({ id }) => {
                        this.fileInfo = {
                            id,
                            contentType: item.file.type as string,
                            extension: item.file.name,
                            filename: item.file.name,
                            size: item.file.size as number,
                        };
                        this.onChanged(data.id);
                    }),
                    finalize(() => {
                        this.loading = false;
                        this.cdr.markForCheck();
                    }),
                )
                .subscribe((data) => {});
        }
    }
    */
    customUpload = (item: NzUploadXHRArgs): Subscription => {
        const file = new Blob([item.postFile as string], { type: item.file.type });
        this.loading = true;

        return this.fileApi.upload(file, item?.file?.name || '').subscribe((data) => {
            this.value = [...this.value, data.id];
            this.fileIds.set(item.file.uid, data.id);
            this.onChanged(this.value);

            this.loading = false;
            this.cdr.markForCheck();
        });
    };

    download(id: string): void {
        const fileId = this.fileIds.get(id);

        if (fileId) {
            this.fileSrv.getFileById(fileId).subscribe((data) => {
                const currentFile = this.fileList.find((item) => item.uid === id);

                saveBlobToFile(data, {
                    contentType: currentFile?.type,
                    extension: currentFile?.type?.split('/')[1],
                    filename: currentFile?.name,
                    id: currentFile?.uid,
                    size: currentFile?.size,
                });
            });
        }
    }

    trackByIndex(idx: number): number {
        return idx;
    }

    private getMeta(fileIds: string[]): void {
        if (fileIds) {
            this.loading = true;

            this.fileApi
                .getFileInfo([...fileIds])
                .pipe(
                    catchError(() => of([])),
                    finalize(() => {
                        this.loading = false;
                        this.cdr.markForCheck();
                    }),
                )
                .subscribe((info) => {
                    this.fileList = [];

                    info.forEach((item) => {
                        this.fileList.push({
                            uid: item.id,
                            name: item.filename,
                            size: item.size,
                            type: item.contentType,
                            lastModified: undefined,
                            lastModifiedDate: undefined,
                            webkitRelativePath: '',
                        });
                    });
                });
        }
    }
}
