import { Injectable, OnDestroy } from '@angular/core';
import {
    BehaviorSubject,
    Observable,
    Subject,
    catchError,
    map,
    of,
    switchMap,
    takeUntil,
    tap,
    ReplaySubject,
} from 'rxjs';

import { NotificationsApiService } from '@api/notifications/notifications-api.service';
import { Notification } from '@api/notifications/types';
import { ProfileService } from '@app/core/services/profile.service';
import { JobsWsEventsService } from '@app/services/ws/jobs-ws-events.service';

@Injectable()
export class NotificationsService implements OnDestroy {
    private readonly unsubscribe$$ = new Subject<void>();
    private readonly notifications$$ = new BehaviorSubject<Notification[]>([]);
    private readonly needUpdateList$$ = new ReplaySubject<void>(1);

    notifications$ = this.notifications$$.asObservable();
    needUpdateList$ = this.needUpdateList$$.asObservable();
    total$ = this.notifications$.pipe(map((val) => val.length));

    constructor(
        private readonly notificationsApiService: NotificationsApiService,
        private readonly profileService: ProfileService,
        private readonly jobsWsevents: JobsWsEventsService,
    ) {}

    init(): void {
        this.getNotifications()
            .pipe(
                switchMap(() => this.jobsWsevents.events$),
                takeUntil(this.unsubscribe$$),
            )
            .subscribe((job) => {
                this.notifications$$.next([...this.notifications$$.value, job]);
            });

        this.jobsWsevents.open();
    }

    close() {
        this.jobsWsevents.close();
    }

    ngOnDestroy(): void {
        this.unsubscribe$$.next();
        this.unsubscribe$$.complete();
    }

    needUpdateList(): void {
        this.needUpdateList$$.next();
    }

    getNotifications(): Observable<Notification[]> {
        if (this.profileService.snaphot?.id) {
            return this.notificationsApiService.getNotifications({ userId: this.profileService.snaphot?.id }).pipe(
                tap((data) => {
                    this.notifications$$.next(data);
                }),
                catchError(() => {
                    this.notifications$$.next([]);

                    return of([] as Notification[]);
                }),
            );
        } else {
            return of([] as Notification[]);
        }
    }

    readNotification(id: string): Observable<void> {
        return this.notificationsApiService.readNotification(id);
    }

    readAllNotifications(): Observable<void> {
        return this.notificationsApiService.readAllNotifications();
    }
}
