<div class="un-notifications-header">
  <span>Уведомления</span>

  <button *ngIf="notifications?.length;" class="un-notifications-header__mark" (click)="readAll()">Отметить все прочитанным</button>
</div>

<div class="un-notifications-body">
  <ng-container *ngIf="loading$ | async; else body">
    <div class="un-notifications-body__spinner">
      <nz-spin [nzSpinning]="loading$ | async"></nz-spin>
    </div>
  </ng-container>

  <ng-template #body>
    <ng-container *ngIf="notifications?.length; else empty">
      <div *ngFor="let notification of notifications; trackBy: trackById" class="un-notifications-body__item" (click)="onClick(notification.id, notification.jobId)">
        <div [ngSwitch]="notification.type" class="un-notifications-body__item-type">
          <span *ngSwitchCase="notificationType.Create">Создана новая работа</span>
          <span *ngSwitchCase="notificationType.Update">Обновлён статус работы</span>
        </div>
        
        <nz-tag>{{ notification.statusName }}</nz-tag>

        <span>{{ notification.jobName }}</span>

        <div class="un-notifications-body__item-footer">
          <span>Создана&nbsp;{{ notification.jobCreator.lastName }} {{ notification.jobCreator.firstName }}</span>
          <span>{{ notification.createdAt | date : 'dd.MM.YYYY' }}</span>
        </div>
      </div>
    </ng-container>

    <ng-template #empty>
      <nz-empty [nzNotFoundContent]="content">
        <ng-template #content>
          <span>Уведомлений нет</span>
        </ng-template>
      </nz-empty> 
    </ng-template>
  </ng-template>
</div>
