<un-content-wrapper class="wrapper">
  <h1>Редактирование профиля</h1>

  <nz-divider></nz-divider>

  <un-form-wrapper [formGroup]="form">
    <un-form-block>
      <un-form-label>Основная информация</un-form-label>

      <un-form-item>
        <un-form-label>
          <nz-form-label nzRequired>Имя</nz-form-label>
        </un-form-label>
        <nz-form-control nzHasFeedback [nzErrorTip]="firstNameErrorTpl" [nzSpan]="12">
          <input nz-input nzSize="large" type="text" placeholder="Укажите имя" formControlName="firstName" nzRequired />
        </nz-form-control>
        <ng-template #firstNameErrorTpl let-control>
          <ng-container *ngIf="control.hasError('required')">Поле обязательно для заполнения</ng-container>
          <ng-container *ngIf="control.hasError('maxlength')">Длина поля не должна превышать 32 символа</ng-container>
        </ng-template>
      </un-form-item>

      <un-form-item>
        <un-form-label><nz-form-label nzRequired>Фамилия</nz-form-label></un-form-label>
        <nz-form-control nzHasFeedback [nzErrorTip]="lastNameErrorTpl" [nzSpan]="12">
          <input
            nz-input
            nzSize="large"
            type="text"
            placeholder="Укажите фамилию"
            formControlName="lastName"
            nzRequired
          />
          <ng-template #lastNameErrorTpl let-control>
            <ng-container *ngIf="control.hasError('required')">Поле обязательно для заполнения</ng-container>
            <ng-container *ngIf="control.hasError('maxlength')">Длина поля не должна превышать 32 символа</ng-container>
          </ng-template>
        </nz-form-control>
      </un-form-item>

      <un-form-item>
        <un-form-label><nz-form-label nzRequired>Должность</nz-form-label></un-form-label>
        <nz-form-control nzHasFeedback [nzErrorTip]="positionErrorTpl" [nzSpan]="12">
          <input
            nz-input
            nzSize="large"
            type="text"
            placeholder="Укажите должность пользователя"
            formControlName="position"
            nzRequired
          />
          <ng-template #positionErrorTpl let-control>
            <ng-container *ngIf="control.hasError('required')">Поле обязательно для заполнения</ng-container>
            <ng-container *ngIf="control.hasError('maxlength')">Длина поля не должна превышать 32 символа</ng-container>
          </ng-template>
        </nz-form-control>
      </un-form-item>

      <un-form-item>
        <un-form-label><nz-form-label nzRequired>Адрес электронной почты</nz-form-label></un-form-label>
        <nz-form-control nzHasFeedback [nzSpan]="12" [nzErrorTip]="emailErrorTpl">
          <input nz-input type="email" nzSize="large" placeholder="Укажите почту" formControlName="email" nzRequired />
          <ng-template #emailErrorTpl let-control>
            <ng-container *ngIf="control.hasError('pattern')">Введите корректный email</ng-container>
            <ng-container *ngIf="control.hasError('required')">Поле обязательно для заполнения</ng-container>
          </ng-template>
        </nz-form-control>
      </un-form-item>

      <un-form-item>
        <un-form-label><nz-form-label nzRequired>Телефонный номер</nz-form-label></un-form-label>
        <nz-form-control nzHasFeedback nzErrorTip="Поле обязательно для заполнения" [nzSpan]="12">
          <input
            nz-input
            nzSize="large"
            type="text"
            placeholder="+7 (555) 678-1212"
            formControlName="phone"
            prefix="+7 "
            mask="(000) 000-00-00"
          />
        </nz-form-control>
      </un-form-item>

      <un-form-item>
        <un-form-label><nz-form-label>Роль</nz-form-label></un-form-label>
        <nz-form-text
          ><span *ngFor="let role of roles" class="role-item">{{ role }}</span></nz-form-text
        >
      </un-form-item>

      <un-form-item>
        <un-form-label>Фото</un-form-label>
        <un-image-uploader formControlName="photoId"></un-image-uploader>
      </un-form-item>
    </un-form-block>

    <un-form-block>
      <un-form-item>
        <div class="form-footer">
          <button nz-button [nzLoading]="loading$ | async" (click)="onCancel()">Отмена</button>
          <button
            nz-button
            nzType="primary"
            type="submit"
            [class.button__disabled]="form.invalid"
            [nzLoading]="loading$ | async"
            [disabled]="form.invalid"
            (click)="onSubmitEditor()"
          >
            Сохранить
          </button>
        </div>
      </un-form-item>
    </un-form-block>
  </un-form-wrapper>
</un-content-wrapper>
