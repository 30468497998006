import { ChangeDetectionStrategy, Component } from '@angular/core';

@Component({
    selector: 'un-base-layout',
    templateUrl: './base-layout.component.html',
    styleUrls: ['./base-layout.component.less'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class BaseLayoutComponent {
    isCollapsed = false;
}
