import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Input, forwardRef } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { FileApiService } from '@api/file/file-api.service';
import { UntilDestroy } from '@ngneat/until-destroy';
import { ImageViewComponent } from '@shared/components/image-view/image-view.component';
import { NzButtonModule } from 'ng-zorro-antd/button';
import { NzIconModule } from 'ng-zorro-antd/icon';
import { NzUploadModule } from 'ng-zorro-antd/upload';
import { NzUploadXHRArgs } from 'ng-zorro-antd/upload/interface';
import { BehaviorSubject, Subscription, finalize } from 'rxjs';
import { IMAGE_MIME_TYPES } from './constants/image-mime-types';
import { ImageChangesCallback } from './types/image-changes-callback';

@UntilDestroy()
@Component({
    selector: 'un-image-uploader',
    standalone: true,
    imports: [CommonModule, NzButtonModule, NzIconModule, NzUploadModule, ImageViewComponent],
    templateUrl: './image-uploader.component.html',
    styleUrls: ['./image-uploader.component.less'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            useExisting: forwardRef(() => ImageUploaderComponent),
            multi: true,
        },
    ],
})
export class ImageUploaderComponent implements ControlValueAccessor {
    @Input()
    set imageFileTypes(types: string[]) {
        this.imageFileAccept = types.filter((type) => IMAGE_MIME_TYPES.includes(type)).join(', ');
    }

    value?: string;
    disabled = false;

    imageFileAccept = IMAGE_MIME_TYPES.join(', ');

    onChanges?: ImageChangesCallback;
    onTouched?: () => void;
    // eslint-disable-next-line @typescript-eslint/no-empty-function
    onChanged: ImageChangesCallback = () => {};

    readonly loading$$ = new BehaviorSubject(false);

    constructor(private readonly cdr: ChangeDetectorRef, private readonly fileApiService: FileApiService) {}

    writeValue(value: string | undefined): void {
        this.value = value;
        this.cdr.markForCheck();
    }

    registerOnChange(onChanges: ImageChangesCallback): void {
        this.onChanges = onChanges;
        this.onChanged = onChanges;
    }

    registerOnTouched(onTouched: () => void): void {
        this.onTouched = onTouched;
    }

    onRemove(event: Event): void {
        event.stopPropagation();
        this.loading$$.next(true);

        if (this.value) {
            this.fileApiService
                .remove(this.value)
                .pipe(
                    finalize(() => {
                        this.loading$$.next(false);
                    }),
                )
                .subscribe();
        }

        this.value = undefined;
        this.onChanged(undefined);
        this.onChanges?.(undefined);
    }

    customUploadReq = (item: NzUploadXHRArgs): Subscription => {
        this.loading$$.next(true);

        const file = new Blob([item.postFile as string], { type: item.file.type });

        return this.fileApiService
            .upload(file, item?.file?.filename || '')
            .pipe(
                finalize(() => {
                    this.loading$$.next(false);
                }),
            )
            .subscribe((data) => {
                this.onChanged(data.id);
                this.onChanges?.(data.id);
                this.writeValue(data.id);
            });
    };

    setDisabledState(disabled: boolean) {
        this.disabled = disabled;
    }
}
