import { Observable } from 'rxjs';

import { Injectable } from '@angular/core';
import { ApiAbstract } from '@api/api-abstract';
import {
    CompanyResource,
    PageServiceCompanyResponseDto,
    SearchCompanyParams,
    ServiceCompanyResponseDto,
    UpdateCompanyRequest,
    UpdateServiceCompaniesRequest,
} from '@api/company/types';
import { DefaultPaginationParams } from '@api/constants';
import { Page, SearchParams } from '@api/types';

@Injectable({
    providedIn: 'root',
})
export class CompanyApiService extends ApiAbstract {
    constructor() {
        super('company');
    }

    getCompanyById(id: string): Observable<CompanyResource> {
        return this.httpClient.get<CompanyResource>(`${this.base}/${id}`);
    }

    updateCompany(id: string, payload: UpdateCompanyRequest): Observable<CompanyResource> {
        return this.httpClient.patch<CompanyResource>(`${this.base}/${id}`, payload);
    }

    blockCompanyById(id: string): Observable<CompanyResource> {
        return this.httpClient.post<CompanyResource>(`${this.base}/${id}`, {});
    }

    unblockCompanyById(id: string): Observable<CompanyResource> {
        return this.httpClient.delete<CompanyResource>(`${this.base}/${id}`);
    }

    searchCompanies(filter: Partial<SearchCompanyParams>, params: SearchParams = DefaultPaginationParams) {
        return this.httpClient.post<Page<CompanyResource>>(`${this.base}/search`, filter, { params });
    }

    searchCompanyService(params: SearchParams = DefaultPaginationParams): Observable<PageServiceCompanyResponseDto> {
        return this.httpClient.get<PageServiceCompanyResponseDto>(`${this.base}/service`, { params });
    }

    getMyCompanyService(): Observable<ServiceCompanyResponseDto[]> {
        return this.httpClient.get<ServiceCompanyResponseDto[]>(`${this.base}/service/my`);
    }

    updateServiceCompanies(data: UpdateServiceCompaniesRequest): Observable<void> {
        return this.httpClient.post<void>(`${this.base}/service`, data);
    }
}
