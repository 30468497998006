<div class="discrete-form" [formGroup]="form">
  <div class="discrete-form__row">
    <span class="discrete-form__label">Частота появления</span>

    <input nz-input formControlName="frequency" type="number" min="0" />
  </div>

  <div class="discrete-form__row">
    <span class="discrete-form__label">Период появления</span>

    <nz-select formControlName="period">
      <nz-option
        *ngFor="let option of timePeriodOptions; trackBy: trackBySelectItem"
        [nzValue]="option.value"
        [nzLabel]="option.label"
      ></nz-option>
    </nz-select>
  </div>

  <div class="discrete-form__row" formGroupName="workTime">
    <span class="discrete-form__label">Время на работу</span>

    <nz-select formControlName="period">
      <nz-option
        *ngFor="let option of timePeriodOptions; trackBy: trackBySelectItem"
        [nzValue]="option.value"
        [nzLabel]="option.label"
      ></nz-option>
    </nz-select>

    <input class="work-time-frequency" nz-input formControlName="count" type="number" min="0" />
  </div>
</div>
