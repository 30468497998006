import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ProfileService } from '@app-core/services/profile.service';
import { Observable } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class AuthInterceptor implements HttpInterceptor {
    constructor(private readonly profile: ProfileService) {}

    intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
        const token = this.profile.token;

        if (token) {
            const cloned = request.clone({
                headers: request.headers.set('Authorization', 'Bearer ' + token),
            });

            return next.handle(cloned);
        } else {
            return next.handle(request);
        }
    }
}
