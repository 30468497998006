<nz-layout class="app-layout">
  <nz-sider
    class="menu-sidebar"
    nzCollapsible
    nzWidth="55px"
    nzBreakpoint="md"
    [nzTrigger]="null"
    [(nzCollapsed)]="isCollapsed"
  >
    <un-side-menu></un-side-menu>
  </nz-sider>

  <nz-layout>
    <un-header></un-header>
    <nz-content>
      <div class="inner-content">
        <router-outlet></router-outlet>
      </div>
    </nz-content>
  </nz-layout>
</nz-layout>
