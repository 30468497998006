<div class="block">
  <div class="block__title">
    {{ month }}
  </div>

  <div class="block__days">
    <div
      *ngFor="let day of days; trackBy: trackByDay"
      class="block__day"
      [class.block__selected]="isSelected(day)"
      (click)="onSelectDays(day)"
    >
      {{ day }}
    </div>
  </div>
</div>
