import { HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { FileDto } from '@api/file/types';
import { ApiAbstract } from '../api-abstract';

@Injectable({
    providedIn: 'root',
})
export class FileApiService extends ApiAbstract {
    constructor() {
        super('file');
    }

    upload(file: Blob, fileName: string): Observable<{ id: string }> {
        const formData = new FormData();
        formData.append('file', file, fileName);
        const headers = new HttpHeaders();
        headers.append('enctype', 'multipart/form-data');

        return this.httpClient.post<{ id: string }>(`${this.base}`, formData, { headers, responseType: 'json' });
    }

    getFileById(fileId: string, token: string | null): Observable<Blob> {
        const params = token ? { token } : undefined;

        return this.httpClient.get(`${this.base}/${fileId}`, {
            responseType: 'blob',
            params,
        });
    }

    getFileInfo(fileIds: string[]): Observable<FileDto[]> {
        return this.httpClient.post<FileDto[]>(`${this.base}/meta`, { fileIds });
    }

    remove(fileId: string) {
        return this.httpClient.delete<void>(`${this.base}/${fileId}`);
    }
}
