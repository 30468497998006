import { SelectItem } from '@un-types/select-item';
import { TimePeriod } from '@un-types/time-period';

export const TIME_PERIOD_OPTIONS: SelectItem<TimePeriod>[] = [
    {
        value: TimePeriod.Minute,
        label: 'Минута',
    },
    {
        value: TimePeriod.Hour,
        label: 'Час',
    },
    {
        value: TimePeriod.Day,
        label: 'День',
    },
    {
        value: TimePeriod.Week,
        label: 'Неделя',
    },
    {
        value: TimePeriod.Month,
        label: 'Месяц',
    },
    {
        value: TimePeriod.Year,
        label: 'Год',
    },
];
