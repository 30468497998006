<div class="item">
  <div class="item__header">
    <div #itemLabelWrapper class="item__header-label">
      <ng-content select="un-form-label"></ng-content>
    </div>

    <div #itemDescriptionWrapper class="item__header-description">
      <ng-content select="[itemDescription]"></ng-content>
    </div>
  </div>

  <ng-content></ng-content>
</div>
