<div class="exact-form" [formGroup]="form">
  <div class="exact-form__row" formGroupName="workTime">
    <div class="exact-form__label">Время на работу</div>

    <nz-select formControlName="period">
      <nz-option
        *ngFor="let option of timePeriodOptions; trackBy: trackBySelectItem"
        [nzValue]="option.value"
        [nzLabel]="option.label"
      ></nz-option>
    </nz-select>

    <input class="work-time-frequency" nz-input formControlName="count" type="number" min="0" />
  </div>

  <un-calendar></un-calendar>
</div>
