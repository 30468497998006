import { AttachedFile } from '@api/file/types';
import { ApiAttributes, DynamicAttr, DynamicAttrTypes } from '@api/types';

export const getAttributes = (attrsJson: ApiAttributes | undefined): DynamicAttr[] => {
    if (!attrsJson) {
        return [];
    }

    if (Array.isArray(attrsJson)) {
        return attrsJson;
    }

    try {
        const parsed = JSON.parse(attrsJson) as DynamicAttr[];

        return Array.isArray(parsed) ? parsed : [];
    } catch {
        return [];
    }
};

export const getAttaches = (attrsJson: ApiAttributes | undefined, urlFn?: (val: string) => string): AttachedFile[] =>
    getAttributes(attrsJson)
        .filter(({ type, value }) => type === DynamicAttrTypes.DynamicFile && !!value)
        .map(({ value, label }) => {
            value = Array.isArray(value) ? (value[0] as string) : (value as string);

            return {
                id: value,
                label,
                url: typeof urlFn === 'function' ? urlFn(value) : value,
            };
        });

export const getAttributeByName = (jsonStr: ApiAttributes | undefined, name: string): DynamicAttr | undefined =>
    getAttributes(jsonStr).find((item) => item.name === name);

export const getAttributeValueByName = <T extends DynamicAttr['name']>(
    jsonStr: ApiAttributes | undefined,
    name: string,
): T | undefined => getAttributeByName(jsonStr, name)?.value as T;
