import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { NzAvatarModule } from 'ng-zorro-antd/avatar';
import { NzBadgeModule } from 'ng-zorro-antd/badge';
import { NzDividerModule } from 'ng-zorro-antd/divider';
import { NzDropDownModule } from 'ng-zorro-antd/dropdown';
import { NzEmptyModule } from 'ng-zorro-antd/empty';
import { NzIconModule } from 'ng-zorro-antd/icon';
import { NzLayoutModule } from 'ng-zorro-antd/layout';
import { NzMenuModule } from 'ng-zorro-antd/menu';
import { NzTagModule } from 'ng-zorro-antd/tag';
import { NzToolTipModule } from 'ng-zorro-antd/tooltip';
import { NzSpinModule } from 'ng-zorro-antd/spin';

import { BreadcrumbsComponent } from '@shared/components/breadcrumbs/breadcrumbs.component';
import { UserAvatarComponent } from '@shared/components/users/user-avatar/user-avatar.component';
import { HeaderComponent } from './components/header/header.component';
import { NotificationsComponent } from './components/notifications/notifications.component';
import { SideMenuComponent } from './components/side-menu/side-menu.component';
import { BaseLayoutComponent, EmptyLayoutComponent } from './containers';
import { NotificationsService } from './services/notifications.service';
import { JobsWsEventsService } from '@app/services/ws/jobs-ws-events.service';
import { NzButtonModule } from 'ng-zorro-antd/button';
// import { ProfileService } from '@app/core/services/profile.service';

@NgModule({
    declarations: [
        BaseLayoutComponent,
        EmptyLayoutComponent,
        HeaderComponent,
        NotificationsComponent,
        SideMenuComponent,
    ],
    imports: [
        BreadcrumbsComponent,
        CommonModule,
        RouterModule,

        NzAvatarModule,
        NzBadgeModule,
        NzDividerModule,
        NzDropDownModule,
        NzEmptyModule,
        NzIconModule,
        NzLayoutModule,
        NzMenuModule,
        NzTagModule,
        NzToolTipModule,
        NzSpinModule,
        NzButtonModule,

        UserAvatarComponent,
    ],
    exports: [BaseLayoutComponent, EmptyLayoutComponent],
    providers: [JobsWsEventsService, NotificationsService],
})
export class LayoutModule {}
