import { HttpClient } from '@angular/common/http';
import { inject } from '@angular/core';
import { environment } from '@env/environment';

export abstract class ApiAbstract {
    protected get apiRootUrl(): string {
        return `${environment.apiBase}/${this.typeApi}/v3`;
    }
    protected get base(): string {
        return `${this.apiRootUrl}/${this.prefix}`;
    }

    protected readonly httpClient = inject(HttpClient);

    constructor(private readonly prefix: string, private readonly typeApi: string = 'app') {}
}
