import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { NzNotificationService } from 'ng-zorro-antd/notification';
import { BehaviorSubject, Observable, delay, finalize, map, tap } from 'rxjs';

import { AuthApiService } from '@api/auth/auth-api.service';
import {
    LoginApiResp,
    LoginForm,
    RecoveryForm,
    RegisterForm,
    RememberMeRequest,
    ResetPasswordForm,
} from '@api/auth/types';
import { UpdateSelfUserRequest } from '@api/user/types';
import { UserApiService } from '@api/user/user-api.service';
import { UserProfile } from '../models/user-profile';

const SleepMs = 2500;

@Injectable({ providedIn: 'root' })
export class ProfileService {
    private readonly pending = new BehaviorSubject(false);
    private readonly current = new BehaviorSubject<UserProfile | null>(null);

    token: string | null = localStorage.getItem('token');
    current$ = this.current.asObservable();
    pending$ = this.pending.asObservable();

    get snaphot() {
        return this.current.getValue();
    }

    constructor(
        private readonly authApi: AuthApiService,
        private readonly userApi: UserApiService,
        private readonly router: Router,
        private readonly nzNotificationService: NzNotificationService,
    ) {}

    login(data: LoginForm): Observable<LoginApiResp> {
        this.pending.next(true);

        return this.authApi.login(data).pipe(
            tap(({ token }) => {
                this.token = token;
                localStorage.setItem('token', token);
            }),
            finalize(() => this.pending.next(false)),
        );
    }

    logout(): void {
        this.pending.next(true);

        this.authApi
            .logout()
            .pipe(
                finalize(() => {
                    this.token = null;
                    localStorage.removeItem('token');
                    this.router.navigate(['/auth']);
                    this.pending.next(false);
                    this.current.next(null);
                }),
            )
            .subscribe();
    }

    register(payload: RegisterForm): Observable<boolean> {
        this.pending.next(true);

        return this.authApi.register(payload).pipe(
            tap(() => {
                this.nzNotificationService.success(
                    'Успешно',
                    'Вам на почту отправлено письмо с ссылкой для подтверждения почты',
                );
            }),
            finalize(() => this.pending.next(false)),
        );
    }

    recovery(payload: RecoveryForm): Observable<boolean> {
        this.pending.next(true);

        return this.authApi.recovery(payload).pipe(
            tap(() => this.nzNotificationService.success('Успешно', 'На вашу почту отправлено письмо к инструкцией')),
            finalize(() => this.pending.next(false)),
        );
    }
    emailConfirm(token: string): Observable<boolean> {
        this.pending.next(true);

        return this.authApi.emailConfirm(token).pipe(
            tap(() => this.nzNotificationService.success('Успешно', 'Ваша почта успешно подтверждена!')),
            map((data) => !!data),
            delay(SleepMs),
            tap(() => this.router.navigateByUrl('/auth')),
            finalize(() => {
                this.pending.next(false);
            }),
        );
    }

    resetPassword(token: string, resetPasswordData: ResetPasswordForm): Observable<boolean> {
        this.pending.next(true);

        return this.authApi.resetPassword(token, resetPasswordData).pipe(
            tap(() => {
                this.nzNotificationService.success(
                    'Успешно',
                    'Ваш пароль обновлен! Теперь можно авторизироваться с новым паролем.',
                );
            }),
            map((data) => !!data),
            delay(SleepMs),
            tap(() => this.router.navigateByUrl('/auth')),
            finalize(() => {
                this.pending.next(false);
            }),
        );
    }

    acceptInvite(token: string, createPasswordData: ResetPasswordForm): Observable<boolean> {
        this.pending.next(true);

        return this.authApi.acceptInvite(token, createPasswordData).pipe(
            tap(() => {
                this.nzNotificationService.success('Успешно', 'Ваш пароль создан! Теперь можно авторизироваться.');
            }),
            map((data) => !!data),
            delay(SleepMs),
            tap(() => this.router.navigateByUrl('/auth')),
            finalize(() => {
                this.pending.next(false);
            }),
        );
    }

    getCurrent(): Observable<UserProfile> {
        return this.userApi.getMe().pipe(
            map((user) => new UserProfile(user)),
            tap((user) => {
                this.current.next(user);
            }),
        );
    }

    update(data: Partial<UpdateSelfUserRequest>) {
        return this.userApi.updateSelf(data).pipe(
            map((user) => new UserProfile(user)),
            tap((user) => {
                this.current.next(user);
            }),
        );
    }

    rememberMe(rememberMeData: RememberMeRequest): Observable<LoginApiResp> {
        this.pending.next(true);

        return this.authApi.rememberMe(rememberMeData).pipe(
            tap(({ token }) => {
                this.token = token;
                localStorage.setItem('token', token);
            }),
            finalize(() => this.pending.next(false)),
        );
    }
}
