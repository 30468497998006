<nz-upload
  [nzShowUploadList]="false"
  [nzCustomRequest]="customUpload"
  [nzBeforeUpload]="beforeUpload"
  [nzDisabled]="fileList.length >= maxFilesAmount"
>
  <div class="upload-button">
    <button
      class="upload-button__trigger"
      nz-button
      [class.upload-button__disabled]="fileList.length >= maxFilesAmount"
    >
      <span nz-icon [nzType]="'upload'"></span>
      <span> Загрузить </span>
    </button>

    <button
      *ngIf="fileList.length > 0 && maxFilesAmount !== 1"
      class="upload-button__remove"
      nz-button
      nzType="default"
      nzShape="circle"
      nzSize="small"
      (click)="onRemoveFile($event)"
    >
      <span nz-icon nzType="close"></span>
    </button>
  </div>
</nz-upload>

<div *ngFor="let file of fileList; trackBy: trackByIndex" class="upload-button">
  <button class="upload-button__trigger" nz-button [nzLoading]="loading" (click)="download(file['uid'])">
    <span nz-icon nzType="icons/common:document"></span>
    <span>
      {{ file.name }}
    </span>
  </button>

  <button
    *ngIf="!disabled"
    class="upload-button__remove"
    nz-button
    nzType="default"
    nzShape="circle"
    nzSize="small"
    (click)="onRemoveFile($event, file['uid'])"
  >
    <span nz-icon nzType="close"></span>
  </button>
</div>
