import { Injectable } from '@angular/core';
import { NzSelectOptionInterface } from 'ng-zorro-antd/select';
import { map, type Observable } from 'rxjs';

import { CompanyApiService } from '@api/company/company-api.service';
import { JobStatusApiService } from '@api/job-status/job-status-api.service';
import { JobTemplateApiService } from '@api/job-template/job-template-api.service';
import { UniodGroupsApiService } from '@api/uniod-groups/uniod-groups-api.service';
import { UniodApiService } from '@api/uniod/uniod-api.service';
import { SearchUserRequest } from '@api/user/types';
import { UserApiService } from '@api/user/user-api.service';
import { AsyncOptionsFn } from '@app/shared/components/controls/async-select/types';
import { getShortLastname } from '@app/shared/utils/helper';

@Injectable()
export class JobFormService {
    constructor(
        private readonly companyApi: CompanyApiService,
        private readonly jobStatusApi: JobStatusApiService,
        private readonly jobTemplateApi: JobTemplateApiService,
        private readonly uniodApi: UniodApiService,
        private readonly uniodGroupApi: UniodGroupsApiService,
        private readonly userApi: UserApiService,
    ) {}

    fetchTemplateOptions(): Observable<NzSelectOptionInterface[]> {
        return this.jobTemplateApi
            .searchTemplates()
            .pipe(map(({ content }) => content.map(({ id, name }) => ({ value: id, label: name }))));
    }

    searchUniod(uniodId: string) {
        return this.uniodApi.getUniodById(uniodId);
    }

    fetchUniodOptions(page: number | undefined): ReturnType<AsyncOptionsFn> {
        return this.uniodApi.list({ page, sort: ['name'] }).pipe(
            map((data) => ({
                options: data.content.map(({ id, name }) => ({ value: id, label: name })),
                page: data.number,
                totalPages: data.totalPages,
            })),
        );
    }

    fetchGroupsOptions(page: number | undefined, name: string | undefined): ReturnType<AsyncOptionsFn> {
        return this.uniodGroupApi.searchGroups({ name }, { page }).pipe(
            map((data) => ({
                options: data.content.map(({ id, name: label }) => ({ value: id, label })),
                page: data.number,
                totalPages: data.totalPages,
            })),
        );
    }

    fetchStatusOptions(statusId: string): Observable<NzSelectOptionInterface[]> {
        return this.jobStatusApi
            .getNextStatuses(statusId)
            .pipe(map((items) => items.map(({ id, name }) => ({ value: id, label: name }))));
    }

    fetchUsersOptions(page: number | undefined, search?: Partial<SearchUserRequest>): ReturnType<AsyncOptionsFn> {
        return this.userApi.list(search, { page, sort: ['firstName', 'lastName'] }).pipe(
            map((data) => ({
                options: data.content.map((item) => ({
                    label: getShortLastname(item),
                    value: item.id,
                })),
                page: data.number,
                totalPages: data.totalPages,
            })),
        );
    }

    fetchServiceCompaniyOptions(): Observable<NzSelectOptionInterface[]> {
        return this.companyApi
            .getMyCompanyService()
            .pipe(map((items) => items.map(({ id, name }) => ({ value: id, label: name }))));
    }
}
