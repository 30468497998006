import { UserPermission } from './types';

export const GanttCtlr = 'ganttChart';
export const GanttPermissions = {
    get: [GanttCtlr, 'get'] as UserPermission,
} as const;

export const UniodControllerCtlr = 'uniodController';
export const UniodControllerPermissions = {
    create: [UniodControllerCtlr, 'create'] as UserPermission,
    list: [UniodControllerCtlr, 'list'] as UserPermission,
    replace: [UniodControllerCtlr, 'replace'] as UserPermission,
    delete: [UniodControllerCtlr, 'delete'] as UserPermission,
} as const;

export const UniodEventCtlr = 'uniodEvent';
export const UniodEventPermissions = {
    search: [UniodEventCtlr, 'search'] as UserPermission,
    searchActual: [UniodEventCtlr, 'searchActual'] as UserPermission,
    searchActualForList: [UniodEventCtlr, 'searchActualForList'] as UserPermission,
    getEventTypes: [UniodEventCtlr, 'getEventTypes'] as UserPermission,
} as const;

export const UniodGroupCtlr = 'uniodGroup';
export const UniodGroupPermissions = {
    create: [UniodGroupCtlr, 'create'] as UserPermission,
    search: [UniodGroupCtlr, 'search'] as UserPermission,
} as const;

export const UniodModelCtlr = 'uniodModel';
export const UniodModelPermissions = {
    create: [UniodModelCtlr, 'create'] as UserPermission,
    search: [UniodModelCtlr, 'search'] as UserPermission,
    getOne: [UniodModelCtlr, 'getOne'] as UserPermission,
    replace: [UniodModelCtlr, 'replace'] as UserPermission,
    delete: [UniodModelCtlr, 'delete'] as UserPermission,
    getFiles: [UniodModelCtlr, 'getFiles'] as UserPermission,
} as const;

export const UniodCtlr = 'uniod';
export const UniodPermissions = {
    create: [UniodCtlr, 'create'] as UserPermission,
    search: [UniodCtlr, 'search'] as UserPermission,
    getOne: [UniodCtlr, 'getOne'] as UserPermission,
    replace: [UniodCtlr, 'replace'] as UserPermission,
    delete: [UniodCtlr, 'delete'] as UserPermission,
    getFiles: [UniodCtlr, 'getFiles'] as UserPermission,
} as const;

export const UniodModelCategoryCtlr = 'uniodModelCategory';
export const UniodModelCategoryPermissions = {
    create: [UniodModelCategoryCtlr, 'create'] as UserPermission,
    getOne: [UniodModelCategoryCtlr, 'getOne'] as UserPermission,
    search: [UniodModelCategoryCtlr, 'search'] as UserPermission,
    replace: [UniodModelCategoryCtlr, 'replace'] as UserPermission,
    delete: [UniodModelCategoryCtlr, 'delete'] as UserPermission,
} as const;

export const UniodStatusCtlr = 'uniodStatus';
export const UniodStatusPermissions = {
    search: [UniodStatusCtlr, 'search'] as UserPermission,
} as const;

export const UniodTranslatorCtlr = 'listener';
export const UniodTranslatorPermissions = {
    create: [UniodTranslatorCtlr, 'create'] as UserPermission,
    delete: [UniodTranslatorCtlr, 'delete'] as UserPermission,
} as const;

export const FileCtlr = 'file';
export const FilePermissions = {
    uploadAttachment: [FileCtlr, 'uploadAttachment'] as UserPermission,
    downloadFile: [FileCtlr, 'downloadFile'] as UserPermission,
    getMeta: [FileCtlr, 'getMeta'] as UserPermission,
    delete: [FileCtlr, 'delete'] as UserPermission,
} as const;

export const JobCtlr = 'job';
export const JobPermissions = {
    create: [JobCtlr, 'create'] as UserPermission,
    getOne: [JobCtlr, 'getOne'] as UserPermission,
    search: [JobCtlr, 'search'] as UserPermission,
    update: [JobCtlr, 'update'] as UserPermission,
} as const;

export const JobGroupCtlr = 'jobGroup';
export const JobGroupPermissions = {
    group: [JobGroupCtlr, 'group'] as UserPermission,
} as const;

export const JobStatusCtlr = 'jobStatus';
export const JobStatusPermissions = {
    create: [JobStatusCtlr, 'create'] as UserPermission,
    getOne: [JobStatusCtlr, 'getOne'] as UserPermission,
    update: [JobStatusCtlr, 'update'] as UserPermission,
    delete: [JobStatusCtlr, 'delete'] as UserPermission,
    search: [JobStatusCtlr, 'search'] as UserPermission,
} as const;

export const JobTemplateCtlr = 'jobTemplate';
export const JobTemplatePermissions = {
    create: [JobTemplateCtlr, 'create'] as UserPermission,
    search: [JobTemplateCtlr, 'search'] as UserPermission,
    getOne: [JobTemplateCtlr, 'getOne'] as UserPermission,
    replace: [JobTemplateCtlr, 'replace'] as UserPermission,
    delete: [JobTemplateCtlr, 'delete'] as UserPermission,
} as const;

export const JobCommentCtlr = 'jobComment';
export const JobCommentPermissions = {
    create: [JobCommentCtlr, 'create'] as UserPermission,
    list: [JobCommentCtlr, 'list'] as UserPermission,
    getOne: [JobCommentCtlr, 'getOne'] as UserPermission,
    delete: [JobCommentCtlr, 'delete'] as UserPermission,
} as const;

export const CompanyCtlr = 'company';
export const CompanyPermissions = {
    getOne: [CompanyCtlr, 'getOne'] as UserPermission,
    getServiceCompanies: [CompanyCtlr, 'getServiceCompanies'] as UserPermission,
    updateListServiceCompanies: [CompanyCtlr, 'updateListServiceCompanies'] as UserPermission,
    includeServiceCompany: [CompanyCtlr, 'includeServiceCompany'] as UserPermission,
    excludeServiceCompany: [CompanyCtlr, 'excludeServiceCompany'] as UserPermission,
    update: [CompanyCtlr, 'update'] as UserPermission,
    search: [CompanyCtlr, 'search'] as UserPermission,
    updateModules: [CompanyCtlr, 'updateModules'] as UserPermission,
} as const;

export const CompanyBlockCtlr = 'companyBlock';
export const CompanyBlockPermissions = {
    create: [CompanyBlockCtlr, 'create'] as UserPermission,
    delete: [CompanyBlockCtlr, 'delete'] as UserPermission,
} as const;

export const ModuleCtlr = 'module';
export const ModulePermissions = {
    getOne: [ModuleCtlr, 'getOne'] as UserPermission,
    updateCompaniesList: [ModuleCtlr, 'updateCompaniesList'] as UserPermission,
} as const;

export const JobRegulationCtlr = 'jobRegulation';
export const JobRegulationPermissions = {
    create: [JobRegulationCtlr, 'create'] as UserPermission,
    getOne: [JobRegulationCtlr, 'getOne'] as UserPermission,
    search: [JobRegulationCtlr, 'search'] as UserPermission,
    update: [JobRegulationCtlr, 'update'] as UserPermission,
    getProcedures: [JobRegulationCtlr, 'getProcedures'] as UserPermission,
} as const;

export const BrokerCtlr = 'broker';
export const BrokerPermissions = {
    create: [BrokerCtlr, 'create'] as UserPermission,
    getOne: [BrokerCtlr, 'getOne'] as UserPermission,
    update: [BrokerCtlr, 'update'] as UserPermission,
    delete: [BrokerCtlr, 'delete'] as UserPermission,
    search: [BrokerCtlr, 'search'] as UserPermission,
} as const;

export const GatewayCtlr = 'gateway';
export const GatewayPermissions = {
    create: [GatewayCtlr, 'create'] as UserPermission,
    getOne: [GatewayCtlr, 'getOne'] as UserPermission,
    update: [GatewayCtlr, 'update'] as UserPermission,
    delete: [GatewayCtlr, 'delete'] as UserPermission,
    search: [GatewayCtlr, 'search'] as UserPermission,
} as const;
