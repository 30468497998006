import { CommonModule } from '@angular/common';
import {
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    Input,
    OnInit,
    TemplateRef,
    forwardRef,
} from '@angular/core';
import { ControlValueAccessor, FormsModule, NG_VALUE_ACCESSOR } from '@angular/forms';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { NzSelectModeType, NzSelectModule, NzSelectOptionInterface } from 'ng-zorro-antd/select';
import { EMPTY, Subject, debounceTime, distinctUntilChanged, finalize, switchMap, take, tap } from 'rxjs';

import { AsyncOptionsFn } from './types';

const SearchDebounce = 250;

@UntilDestroy()
@Component({
    selector: 'un-async-select[unOptionsSrc]',
    standalone: true,
    imports: [CommonModule, NzSelectModule, FormsModule],
    templateUrl: './async-select.component.html',
    styleUrls: ['./async-select.component.less'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            useExisting: forwardRef(() => AsyncSelectComponent),
            multi: true,
        },
    ],
})
export class AsyncSelectComponent implements ControlValueAccessor, OnInit {
    @Input()
    unShowSearch = false;

    @Input()
    unAllowClear = false;

    @Input()
    unDisabled = false;

    @Input()
    unPlaceHolder: string | TemplateRef<unknown> | null = null;

    @Input()
    unMode: NzSelectModeType = 'default';

    @Input()
    set unOptionsSrc(fn: AsyncOptionsFn) {
        this.optionsFn = fn;
        this.fetchOptions().subscribe();
    }

    options: NzSelectOptionInterface[] = [];
    value: unknown;
    loading = false;

    private pageIdx = 0;
    private totalPages = 0;
    private readonly search$ = new Subject<string | undefined>();
    private searchVal?: string;
    private optionsInt: NzSelectOptionInterface[] = [];
    private optionsFn?: AsyncOptionsFn;

    constructor(private readonly cdr: ChangeDetectorRef) {}

    ngOnInit(): void {
        if (this.unShowSearch) {
            this.search$
                .pipe(
                    distinctUntilChanged(),
                    debounceTime(SearchDebounce),
                    tap((val) => {
                        this.searchVal = val || undefined;
                        this.optionsInt = [];
                    }),
                    switchMap((val) => this.fetchOptions(0, val || undefined)),
                    untilDestroyed(this),
                )
                .subscribe();
        }
    }

    onChange?: (value: unknown) => void;
    onTouched?: () => void;

    registerOnChange(fn: (value: unknown) => void): void {
        this.onChange = fn;
    }

    registerOnTouched(fn: () => void): void {
        this.onTouched = fn;
    }

    writeValue(val: unknown): void {
        this.value = val;
        this.cdr.markForCheck();
    }

    onChangeModel(val: unknown) {
        this.onChange?.(val);
    }

    onScrollToBottom(): void {
        if (this.pageIdx + 1 < this.totalPages) {
            this.fetchOptions(this.pageIdx + 1, this.searchVal).subscribe();
        }
    }

    onSearch(val: string) {
        this.search$.next(val || undefined);
    }

    private fetchOptions(page?: number, search?: string) {
        if (typeof this.optionsFn === 'function') {
            this.loading = true;

            return this.optionsFn(page, search).pipe(
                take(1),
                finalize(() => {
                    this.loading = false;
                }),
                tap(({ options, page: pageIdx, totalPages }) => {
                    this.options = [...this.optionsInt, ...options];
                    this.optionsInt = this.options;
                    this.pageIdx = pageIdx;
                    this.totalPages = totalPages;

                    this.cdr.markForCheck();
                }),
                untilDestroyed(this),
            );
        }

        return EMPTY;
    }
}
