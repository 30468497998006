import { ChangeDetectionStrategy, Component, inject } from '@angular/core';
import { combineLatest, map } from 'rxjs';

import { isPublishedRoute } from '@app/core/helpers';
import { AppConfigService } from '@app/core/services/app-config.service';
import { ProfileService } from '@app/core/services/profile.service';
import { environment } from '@env/environment';
import { MenuItems } from './consts';

@Component({
    selector: 'un-side-menu',
    templateUrl: './side-menu.component.html',
    styleUrls: ['./side-menu.component.less'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SideMenuComponent {
    isCollapsed = true;
    isDevelop = !environment.production;
    version = `Версия ${environment.version}`;

    items = combineLatest({ user: inject(ProfileService).current$, config: inject(AppConfigService).current$ }).pipe(
        map(({ user, config }) =>
            MenuItems.filter(
                (item) =>
                    isPublishedRoute(item.data) &&
                    (!item.data.configPath || config?.[item.data.configPath]) &&
                    item.data.permissions &&
                    user?.hasAnyPermission(item.data.permissions),
            ).map((item) => (item.data.configPath ? { ...item, path: config?.[item.data.configPath] } : item)),
        ),
    );

    trackBy = <T extends { readonly path?: string }>(index: number, item: T) => item.path ?? index;

    isExternal(link?: string): boolean {
        return !!link?.includes('://');
    }
}
