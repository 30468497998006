import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormBlockComponent } from './components/form-block/form-block.component';
import { FormItemComponent } from './components/form-item/form-item.component';
import { FormLabelComponent } from './components/form-label/form-label.component';
import { FormWrapperComponent } from './components/form-wrapper/form-wrapper.component';

@NgModule({
    declarations: [FormWrapperComponent, FormBlockComponent, FormItemComponent, FormLabelComponent],
    imports: [CommonModule],
    exports: [FormWrapperComponent, FormBlockComponent, FormItemComponent, FormLabelComponent],
})
export class FormWrapperModule {}
