import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { NzInputModule } from 'ng-zorro-antd/input';
import { NzSegmentedModule } from 'ng-zorro-antd/segmented';
import { NzSelectModule } from 'ng-zorro-antd/select';
import { DiscreteFormComponent } from './components/discrete-form/discrete-form.component';
import { ExactFormComponent } from './components/exact-form/exact-form.component';
import { ScheduleEditorComponent } from './schedule-editor.component';
import { CalendarModule } from '../calendar/calendar.module';

const NZ_MODULES = [NzInputModule, NzSelectModule, NzSegmentedModule];

@NgModule({
    declarations: [ScheduleEditorComponent, DiscreteFormComponent, ExactFormComponent],
    imports: [CommonModule, ReactiveFormsModule, ...NZ_MODULES, CalendarModule],
    exports: [ScheduleEditorComponent],
})
export class ScheduleEditorModule {}
