import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { ApiAbstract } from '@api/api-abstract';
import { DefaultPaginationParams } from '@api/constants';
import { Page, SearchParams } from '@api/types';
import {
    FullUserResource,
    InviteUserRequest,
    ListUsers,
    PluralUserResource,
    SearchUserRequest,
    UpdateSelfUserRequest,
    UpdateUserRequest,
} from './types';

@Injectable({ providedIn: 'root' })
export class UserApiService extends ApiAbstract {
    constructor() {
        super('user');
    }

    getMe(): Observable<FullUserResource> {
        return this.httpClient.get<FullUserResource>(`${this.base}/me`);
    }

    search(filter: Partial<SearchUserRequest> = {}, params?: SearchParams) {
        return this.httpClient.post<Page<PluralUserResource>>(`${this.base}/search`, filter, {
            params: { ...DefaultPaginationParams, ...params },
        });
    }

    list(filter: Partial<SearchUserRequest> = {}, params?: SearchParams): Observable<ListUsers> {
        return this.httpClient.post<ListUsers>(`${this.base}/list`, filter, {
            params: { ...DefaultPaginationParams, ...params },
        });
    }

    getById(id: string): Observable<FullUserResource> {
        return this.httpClient.get<FullUserResource>(`${this.base}/${id}`);
    }

    createUser(user: InviteUserRequest): Observable<FullUserResource> {
        return this.httpClient.post<FullUserResource>(`${this.base}/invite`, user);
    }

    updateUser(id: string, data: Partial<UpdateUserRequest>): Observable<FullUserResource> {
        return this.httpClient.patch<FullUserResource>(`${this.base}/${id}`, data);
    }

    blockUser(id: string): Observable<FullUserResource> {
        return this.httpClient.post<FullUserResource>(`${this.base}/${id}/block`, {
            id,
        });
    }

    unblockUser(id: string): Observable<FullUserResource> {
        return this.httpClient.delete<FullUserResource>(`${this.base}/${id}/block`);
    }

    updateSelf(data: Partial<UpdateSelfUserRequest>): Observable<FullUserResource> {
        return this.httpClient.patch<FullUserResource>(`${this.base}/self`, data);
    }
}
