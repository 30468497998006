import {
    AdministrationRoute,
    FacilityGroupsRoute,
    FacilityRoute,
    JournalRoute,
    ModelsRoute,
    UsersRoute,
} from '@app/routes';

export const MenuItems = [
    { ...JournalRoute, icon: 'icons/menu:jobs' },
    { ...FacilityRoute, icon: 'icons/menu:objects' },
    { ...FacilityGroupsRoute, icon: 'icons/menu:groups' },
    { ...ModelsRoute, icon: 'icons/menu:models' },
    { ...UsersRoute, icon: 'icons/menu:users' },
    { ...AdministrationRoute, icon: 'icons/menu:administration' },
] as const;
