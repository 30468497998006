import { AbstractControl, ValidatorFn, ValidationErrors } from '@angular/forms';

export const dateValidator = (dateStart: string, dateEnd: string): ValidatorFn => {
    return (group: AbstractControl): ValidationErrors => {
        const startControl = group.get(dateStart);
        const endControl = group.get(dateEnd);

        if (!startControl || !endControl) {
            return {};
        }

        if (startControl.value >= endControl.value) {
            endControl.setErrors({ date: true });
            startControl.setErrors({ date: true });
        } else {
            endControl.setErrors(null);
            startControl.setErrors(null);
        }

        return {};
    };
};
