<nz-breadcrumb *ngIf="breadcrumbs$ | async as breadcrumbs">
  <nz-breadcrumb-item *ngFor="let breadcrumb of breadcrumbs; let isLast = last; trackBy: trackBreadcrumbsByUrl">
    <ng-container
      *ngTemplateOutlet="
        isLinkBreadcrumb(breadcrumb, isLast) ? linkBreadcrumb : defaultBreadcrumb;
        context: { $implicit: breadcrumb }
      "
    ></ng-container>
  </nz-breadcrumb-item>
</nz-breadcrumb>

<ng-template #linkBreadcrumb let-breadcrumb>
  <a [routerLink]="breadcrumb.url">
    {{ breadcrumb.label }}
  </a>
</ng-template>

<ng-template #defaultBreadcrumb let-breadcrumb>
  {{ breadcrumb.label }}
</ng-template>
