import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { ApiAbstract } from '@api/api-abstract';
import { DefaultPaginationParams } from '@api/constants';
import { FileDto } from '@api/file/types';
import { Page, SearchParams } from '@api/types';
import { JobListRequest, JobsTableItem } from '@app/modules/jobs/types/jobs-table';
import { CreateJobRequest, JobResource, UpdateJobRequest } from './types';

@Injectable({
    providedIn: 'root',
})
export class JobsApiService extends ApiAbstract {
    constructor() {
        super('job');
    }

    getJobDataById(id: string): Observable<JobResource> {
        return this.httpClient.get<JobResource>(`${this.base}/${id}`);
    }

    getJobsList(params: Partial<JobListRequest>, paginationParams?: SearchParams) {
        return this.httpClient.post<Page<JobsTableItem>>(
            `${this.base}/search`,
            { ...params },
            { params: { ...DefaultPaginationParams, ...paginationParams } },
        );
    }

    createJob(data: Partial<CreateJobRequest>): Observable<JobResource> {
        return this.httpClient.post<JobResource>(`${this.base}`, {
            ...data,
        });
    }

    updateJob(id: string, params: Partial<UpdateJobRequest>): Observable<JobResource> {
        return this.httpClient.patch<JobResource>(`${this.base}/${id}`, {
            ...params,
        });
    }

    getFileList(id: string): Observable<FileDto[]> {
        return this.httpClient.get<FileDto[]>(`${this.base}/${id}/files`);
    }
}
