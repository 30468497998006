import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';

@Injectable()
export class ScrollableBodyService {
    private readonly scrollable$$ = new BehaviorSubject<boolean>(true);

    readonly scrollable$: Observable<boolean> = this.scrollable$$.asObservable();

    setScrollable(isScrollable: boolean): void {
        this.scrollable$$.next(isScrollable);
    }
}
