<nz-spin [nzSpinning]="commentsLoading$ | async">
  <div class="job-comments">
    <div class="job-comments__title">
      Комментарии
      <span>{{ comments.length || 0 }}</span>
    </div>
    <ng-container *ngIf="comments?.length; else emptyComments">
      <un-job-comment
        *ngFor="let comment of comments; trackBy: trackById"
        [comment]="comment"
        (deleteComment)="onDeleteComment($event)"
      ></un-job-comment>
    </ng-container>
  </div>
</nz-spin>

<ng-template #emptyComments>
  <p>Комментариев ещё нет</p>
</ng-template>

<textarea class="job-comments__area" nz-input [formControl]="commentCtrl"></textarea>
<button
  nz-button
  nzType="default"
  [disabled]="!commentCtrl.valid"
  [nzLoading]="commentsLoading$ | async"
  (click)="createComment()"
>
  Добавить комментарий
</button>
