import { ChangeDetectionStrategy, Component } from '@angular/core';

@Component({
    selector: 'un-form-wrapper',
    templateUrl: './form-wrapper.component.html',
    styleUrls: ['./form-wrapper.component.less'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
/** @deprecated */
export class FormWrapperComponent {}
