<div #leftSidebarWrapper class="left-sidebar" [hidden]="!showLeftSidebar">
  <ng-content select="[unLeftSidebar]"></ng-content>
</div>

<div #leftResizer class="left-resizer" [hidden]="!showLeftSidebar || !isResizable"></div>

<div class="body" [class.body__scrollable]="scrollable$ | async">
  <ng-content></ng-content>
</div>

<div #rightSidebarWrapper class="right-sidebar" [hidden]="!showRightSidebar">
  <ng-content select="[unRightSidebar]"></ng-content>
</div>