import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { ApiAbstract } from '@api/api-abstract';
import { Page, SearchParams } from '@api/types';
import { DefaultPaginationParams } from '@api/constants';
import { GroupCreateData, GroupData, GroupRegulationData, GroupUpdateData } from './types';

@Injectable({
    providedIn: 'root',
})
export class UniodGroupsApiService extends ApiAbstract {
    constructor() {
        super('uniod/group');
    }

    getGroupData(id: string): Observable<GroupData> {
        return this.httpClient.get<GroupData>(`${this.base}/${id}`);
    }

    createGroup(data: GroupCreateData): Observable<GroupData> {
        return this.httpClient.post<GroupData>(`${this.base}`, data);
    }

    editGroup(id: string, data: GroupCreateData): Observable<GroupData> {
        return this.httpClient.patch<GroupData>(`${this.base}/${id}`, data);
    }

    deleteGroup(id: string): Observable<void> {
        return this.httpClient.delete<void>(`${this.base}/${id}`);
    }

    updateGroup(id: string, data: GroupUpdateData): Observable<void> {
        return this.httpClient.post<void>(`${this.base}/${id}`, data);
    }

    searchGroups(
        filter: Partial<GroupCreateData>,
        params: SearchParams = DefaultPaginationParams,
    ): Observable<Page<GroupData>> {
        return this.httpClient.post<Page<GroupData>>(`${this.base}/search`, filter, { params });
    }

    linkRegulation(data: GroupRegulationData): Observable<GroupData> {
        return this.httpClient.post<GroupData>(`${this.base}/link/regulation`, data);
    }

    unlinkRegulation(data: GroupRegulationData): Observable<GroupData> {
        return this.httpClient.post<GroupData>(`${this.base}/unlink/regulation`, data);
    }
}
