import { inject } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivateFn, Router } from '@angular/router';
import { map } from 'rxjs';

import { ForbiddenRoute } from '@app/routes';
import { isPublishedRoute } from '../helpers';
import { ProfileService } from '../services/profile.service';
import { ProtectedRoute } from '../types';

const profile$ = () => {
    const profile = inject(ProfileService);

    if (profile.token && !profile.snaphot) {
        return profile.getCurrent();
    }

    return profile.current$;
};

export const hasPermission: CanActivateFn = (route: ActivatedRouteSnapshot) => {
    const router = inject(Router);

    return profile$().pipe(
        map(
            (current) =>
                current?.hasPermission(route.data['permission']) || router.createUrlTree([ForbiddenRoute.path]),
        ),
    );
};

export const hasAnyPermission: CanActivateFn = (route: ActivatedRouteSnapshot) => {
    const router = inject(Router);

    return profile$().pipe(
        map(
            (current) =>
                current?.hasAnyPermission(route.data['permissions']) || router.createUrlTree([ForbiddenRoute.path]),
        ),
    );
};

export const hasAllPermissions: CanActivateFn = (route: ActivatedRouteSnapshot) => {
    const router = inject(Router);

    return profile$().pipe(
        map(
            (current) =>
                current?.hasAllPermissions(route.data['permissions']) || router.createUrlTree([ForbiddenRoute.path]),
        ),
    );
};

export const developOnly: CanActivateFn = (route: ActivatedRouteSnapshot) =>
    isPublishedRoute(route.data) || inject(Router).createUrlTree(['/no-access']);

export const defaultRouteGuard =
    (routes: ProtectedRoute[]): CanActivateFn =>
    (route) => {
        const router = inject(Router);

        return profile$().pipe(
            map((profile) => {
                const accessible = routes.filter(
                    (item) => profile?.hasAnyPermission(item.data['permissions']) && isPublishedRoute(item.data),
                )?.[0];

                if (accessible?.path) {
                    const parts = route.pathFromRoot
                        .flatMap((item) => item.url.map((urlSegment) => urlSegment.path))
                        .filter(Boolean);

                    parts.push(accessible.path);

                    return router.parseUrl(parts.join('/'));
                }

                return router.parseUrl(`/${ForbiddenRoute.path}`);
            }),
        );
    };
