import { CommonModule } from '@angular/common';
import {
    ChangeDetectionStrategy,
    Component,
    EventEmitter,
    Input,
    OnChanges,
    OnInit,
    Output,
    SimpleChanges,
} from '@angular/core';
import { FormControl, NonNullableFormBuilder, ReactiveFormsModule, Validators } from '@angular/forms';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { NzAvatarModule } from 'ng-zorro-antd/avatar';
import { NzDatePickerModule } from 'ng-zorro-antd/date-picker';
import { NzDividerModule } from 'ng-zorro-antd/divider';
import { NzFormModule } from 'ng-zorro-antd/form';
import { NzInputModule } from 'ng-zorro-antd/input';
import { NzSelectModule } from 'ng-zorro-antd/select';
import { BehaviorSubject, distinctUntilChanged, filter, of, switchMap } from 'rxjs';

import { CreateJobRequest, JobResource, JobTemplateType, UpdateJobRequest } from '@api/jobs/types';
import { UniodResource } from '@api/uniod/types/uniod';
import { CheckListInputComponent } from '@app/shared/components/check-list-input/check-list-input.component';
import { AsyncSelectComponent } from '@app/shared/components/controls/async-select/async-select.component';
import { AsyncOptionsFn } from '@app/shared/components/controls/async-select/types';
import { FileUploaderButtonComponent } from '@app/shared/components/files/file-uploader-button/file-uploader-button.component';
import { ImageViewComponent } from '@app/shared/components/image-view/image-view.component';
import { ScheduleEditorModule } from '@app/shared/components/schedule-editor/schedule-editor.module';
import { InitialsPipe } from '@app/shared/pipes/initials.pipe';
import { dateValidator } from '@app/shared/validators/date-validator';
import { getAttributes } from '../../dynamic-form/helpers';
import { MAX_DESCRIPTION_LENGTH, MAX_NAME_LENGTH } from '../constants';
import { JobCommentsComponent } from '../job-comments/job-comments.component';
import { JobForm } from '../types';
import { JobFormService } from './job-form.service';

@UntilDestroy()
@Component({
    selector: 'un-job',
    templateUrl: './job-form.component.html',
    styleUrls: ['./job-form.component.less'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: true,
    imports: [
        CommonModule,
        ReactiveFormsModule,

        NzFormModule,
        NzSelectModule,
        NzDividerModule,
        NzAvatarModule,
        NzInputModule,
        NzDatePickerModule,

        ImageViewComponent,
        InitialsPipe,
        JobCommentsComponent,
        AsyncSelectComponent,
        FileUploaderButtonComponent,
        CheckListInputComponent,
        ScheduleEditorModule,
    ],
    providers: [JobFormService],
})
export class JobFormComponent implements OnInit, OnChanges {
    private readonly job$ = new BehaviorSubject<JobResource | null>(null);

    @Input()
    newJobType!: JobTemplateType;

    @Input()
    set jobData(val: JobResource | null | undefined) {
        this.job$.next(val ?? null);
    }

    get jobData() {
        return this.job$.getValue();
    }

    @Input()
    set uniodId(val: string | undefined) {
        if (val) {
            this.jobFormService
                .searchUniod(val)
                .pipe(untilDestroyed(this))
                .subscribe((data) => {
                    this.uniod = data;
                    this.fileSrc = data.model.image.id;
                    this.form.patchValue({ uniodId: data.id });
                });
        } else {
            this.uniod = undefined;
        }
    }

    @Output()
    formChange = new EventEmitter<CreateJobRequest | UpdateJobRequest>();

    @Output()
    validityChange = new EventEmitter<boolean>();

    readonly form = this.fb.group(
        {
            name: this.fb.control<JobForm['name']>('', [Validators.required, Validators.maxLength(MAX_NAME_LENGTH)]),
            uniodId: this.fb.control<JobForm['uniodId']>(null),
            groupUniodsId: this.fb.control<JobForm['groupUniodsId']>(null),
            description: this.fb.control<JobForm['uniodId']>(null, [Validators.maxLength(MAX_DESCRIPTION_LENGTH)]),
            statusId: this.fb.control<JobForm['statusId']>(null, Validators.required),
            attachmentIds: this.fb.control<JobForm['attachmentIds']>(null),
            checkList: new FormControl<JobForm['checkList']>(null),
            responsibleId: this.fb.control<JobForm['responsibleId']>(null),
            performerId: this.fb.control<JobForm['performerId']>(null),
            performerCompanyId: this.fb.control<JobForm['performerCompanyId']>(null),
            deadline: this.fb.control<JobForm['deadline']>(null, Validators.required),
            startAt: this.fb.control<JobForm['startAt']>(new Date(), [Validators.required]),
        },
        { validators: dateValidator('startAt', 'deadline') },
    );

    readonly jobTemplateCtrl = new FormControl();
    readonly templates$ = this.jobFormService.fetchTemplateOptions();
    readonly fetchUniodOptions: AsyncOptionsFn = (page) => this.jobFormService.fetchUniodOptions(page);
    readonly fetchGroupOptions: AsyncOptionsFn = (page, name) => this.jobFormService.fetchGroupsOptions(page, name);

    readonly statuses$ = this.job$.pipe(
        switchMap((job) => (job?.status.id ? this.jobFormService.fetchStatusOptions(job.status.id) : of([]))),
    );

    readonly fetchResponsibleOptions: AsyncOptionsFn = (page, firstName) =>
        this.jobFormService.fetchUsersOptions(page, { firstName, roles: ['ROLE_RESPONSIBLE'] });

    readonly fetchPerformerOptions: AsyncOptionsFn = (page, firstName) =>
        this.jobFormService.fetchUsersOptions(page, { firstName, roles: ['ROLE_PERFORMER'] });

    readonly companyList$ = this.jobFormService.fetchServiceCompaniyOptions();

    uniod?: UniodResource;

    isNew = true;
    hasCheckList = false;
    fileSrc?: string;

    constructor(private readonly fb: NonNullableFormBuilder, private readonly jobFormService: JobFormService) {}

    ngOnInit(): void {
        this.job$
            .pipe(
                distinctUntilChanged((prev, current) => prev?.id !== current?.id),
                untilDestroyed(this),
            )
            .subscribe((data) => {
                this.patchForm(data);
            });

        if (this.isNew) {
            this.form.get('statusId')?.removeValidators(Validators.required);
        } else {
            this.form.get('statusId')?.addValidators(Validators.required);
        }

        this.form.valueChanges.pipe(untilDestroyed(this)).subscribe((data) => {
            this.validityChange.emit(this.form.valid);
            this.formChange.emit(data as CreateJobRequest);
        });

        this.form.controls.uniodId.valueChanges
            .pipe(
                filter((val) => !!val),
                untilDestroyed(this),
            )
            .subscribe(() => {
                this.form.controls.groupUniodsId.setValue(null);
            });

        this.form.controls.groupUniodsId.valueChanges
            .pipe(
                filter((val) => !!val),
                untilDestroyed(this),
            )
            .subscribe(() => {
                this.form.controls.uniodId.setValue(null);
            });

        this.form.controls.performerId.valueChanges
            .pipe(
                filter((val) => !!val),
                untilDestroyed(this),
            )
            .subscribe(() => {
                this.form.controls.performerCompanyId.setValue(null);
            });

        this.form.controls.performerCompanyId.valueChanges
            .pipe(
                filter((val) => !!val),
                untilDestroyed(this),
            )
            .subscribe(() => {
                this.form.controls.performerId.setValue(null);
            });
    }

    ngOnChanges(changes: SimpleChanges): void {
        if (changes['jobData']) {
            this.patchForm(changes['jobData'].currentValue);
        }
    }

    private patchForm(jobData?: JobResource | null) {
        this.isNew = !jobData?.id;
        const hasCheckList = !!jobData?.checkList;

        if (jobData) {
            this.fileSrc = jobData?.uniod?.model.image?.id;

            this.form.patchValue({
                attachmentIds: jobData.attachments?.map(({ id }) => id as string).filter((id) => !!id) ?? [],
                checkList: getAttributes(jobData.checkList),
                deadline: jobData.deadline,
                description: jobData.description,
                groupUniodsId: jobData.groupUniods?.id,
                name: jobData.name,
                performerCompanyId: jobData.performerCompany?.id,
                performerId: jobData.performer?.id,
                responsibleId: jobData.responsible?.id,
                startAt: jobData.startAt,
                statusId: jobData.status?.id,
                uniodId: jobData.uniod?.id,
            });
        }

        this.hasCheckList = hasCheckList;
    }
}
