import { Injectable } from '@angular/core';
import { type Observable } from 'rxjs';

import { ApiAbstract } from '@api/api-abstract';
import { DefaultPaginationParams } from '@api/constants';
import { JobTemplate } from '@api/job-template/types';
import { Page, SearchParams } from '@api/types';

@Injectable({
    providedIn: 'root',
})
export class JobTemplateApiService extends ApiAbstract {
    constructor() {
        super('job/template');
    }

    searchTemplates(name?: string, params?: SearchParams) {
        return this.httpClient.post<Page<JobTemplate>>(
            `${this.base}/search`,
            { name },
            {
                params: { ...DefaultPaginationParams, ...params },
            },
        );
    }

    createJobTemplate(jobTemplate: Pick<JobTemplate, 'name' | 'attributes'>): Observable<JobTemplate> {
        return this.httpClient.post<JobTemplate>(`${this.base}`, { jobTemplate });
    }

    getJobTemplateById(id: string): Observable<JobTemplate> {
        return this.httpClient.get<JobTemplate>(`${this.base}/${id}`);
    }

    updateJobTemplate(id: string, payload: Pick<JobTemplate, 'name' | 'attributes'>): Observable<JobTemplate> {
        return this.httpClient.put<JobTemplate>(`${this.base}/${id}`, payload);
    }

    deleteJobTemplate(id: string): Observable<void> {
        return this.httpClient.delete<void>(`${this.base}/${id}`);
    }
}
