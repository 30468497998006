import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, NavigationEnd, Router } from '@angular/router';

import { Breadcrumb } from '@un-types/breadcrumb';
import { BehaviorSubject, Subject, filter, map } from 'rxjs';

@Injectable({
    providedIn: 'root',
})
export class BreadcrumbsService {
    private readonly breadcrumbs$$ = new BehaviorSubject<Breadcrumb[]>([]);
    private readonly returnedBreadcrumb$$ = new Subject<void>();

    readonly breadcrumbs$ = this.breadcrumbs$$.asObservable();
    readonly returnedBreadcrumb$ = this.returnedBreadcrumb$$.asObservable();

    constructor(private readonly router: Router) {
        this.initializeRouterChanges();
    }

    private initializeRouterChanges(): void {
        this.router.events
            .pipe(
                filter((event) => event instanceof NavigationEnd),
                map((e) => e as NavigationEnd),
            )
            .subscribe((_event) => {
                let route: ActivatedRouteSnapshot | null = this.router.routerState.snapshot.root;
                const brdcrmbs = [];

                while (route != null) {
                    if (!!route.routeConfig?.resolve?.['parents'] && Array.isArray(route.data?.['parents'])) {
                        route.data['parents'].forEach((item: { id: string; name: string }) => {
                            const parentUrl = this.getRouteUrl(route?.parent);
                            brdcrmbs.push({ url: `${parentUrl}/${item.id}`, label: item.name });
                        });
                    }

                    if (route.routeConfig?.title) {
                        const url = this.getRouteUrl(route);
                        brdcrmbs.push({ url, label: route.title as string });
                    }

                    route = route.firstChild;
                }

                this.breadcrumbs$$.next(brdcrmbs);
            });
    }

    private getRouteUrl(route: ActivatedRouteSnapshot | null | undefined): string {
        return (
            '/' +
                route?.pathFromRoot
                    .map((snap) =>
                        snap.url
                            .map(({ path }) => path)
                            .filter(Boolean)
                            .join('/'),
                    )
                    .filter(Boolean)
                    .join('/') ?? ''
        );
    }
}
