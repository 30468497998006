import {
    ChangeDetectionStrategy,
    Component,
    EventEmitter,
    Input,
    OnChanges,
    Output,
    SimpleChanges,
} from '@angular/core';
import { CalendarBlock } from '../../types/calendar-block';
import { CalendarDatesDto } from '@api/job-regulation/types/calendar-regulation';

@Component({
    selector: 'un-calendar-block',
    templateUrl: './calendar-block.component.html',
    styleUrls: ['./calendar-block.component.less'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CalendarBlockComponent implements OnChanges {
    @Input()
    set block(value: CalendarBlock) {
        this.month = value.monthName;
        this.monthIndex = value.monthIndex;
        this.days = [...(new Array(value.daysCount) as never[])].map((_, index) => index + 1);
    }

    @Input()
    dates: CalendarDatesDto[] = [];

    @Output()
    readonly selectDays = new EventEmitter<number[]>();

    selectedDays: number[] = [];

    month?: string;
    monthIndex?: number;
    days?: number[];

    ngOnChanges(changes: SimpleChanges): void {
        changes['dates']?.currentValue?.find((item: CalendarDatesDto) => {
            if (item.month === this.monthIndex) {
                this.selectedDays = [...item.days];
            }
        });
    }

    onSelectDays(day: number): void {
        if (!this.selectedDays?.find((item) => item === day)) {
            this.selectedDays.push(day);
        } else {
            const index = this.selectedDays?.findIndex((item) => item === day);

            this.selectedDays.splice(index, 1);
        }

        this.selectDays.emit([...this.selectedDays]);
    }

    trackByDay(_: number, day: number): number {
        return day;
    }

    isSelected(day: number): boolean {
        return !!this.selectedDays.find((item) => item === day);
    }
}
