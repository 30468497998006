import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CalendarComponent } from './calendar.component';
import { CalendarBlockComponent } from './components/calendar-block/calendar-block.component';

@NgModule({
    declarations: [CalendarComponent, CalendarBlockComponent],
    imports: [CommonModule],
    exports: [CalendarComponent, CalendarBlockComponent],
})
export class CalendarModule {}
