/**
 * @deprecated
 */
export type Links = Record<string, { href: string }>;

/**
 * @deprecated
 */
export interface ResponseData {
    page?: PageMeta;
}

export enum DynamicAttrTypes {
    DynamicString = 'DynamicString',
    DynamicNumber = 'DynamicNumber',
    DynamicBoolean = 'DynamicBoolean',
    DynamicPhoto = 'DynamicPhoto',
    DynamicFile = 'DynamicFile',
    DynamicLink = 'DynamicLink',
    DynamicPhone = 'DynamicPhone',
    DynamicEmail = 'DynamicEmail',
}

export interface DynamicAttr {
    name: string;
    label: string;
    value: string | number | boolean;
    type: DynamicAttrTypes;
    required: boolean;
    editable: boolean;
}

export type ApiAttributes = string | DynamicAttr[] | null;

export interface PageMeta {
    size: number;
    totalElements: number;
    totalPages: number;
    number: number;
}

export interface Paged<EmbeddedType> {
    _embedded?: EmbeddedType;
    page: PageMeta;
}

export type SearchParams = {
    page?: number;
    size?: number;
    sort?: string[];
};

export interface SortObject {
    sorted: boolean;
    unsorted: boolean;
    empty: boolean;
}

export interface PageableObject {
    pageNumber: number;
    pageSize: number;
    sort: SortObject;
    paged: boolean;
    unpaged: boolean;
    offset: number;
}

export interface Page<T> {
    totalPages: number;
    totalElements: number;
    pageable: PageableObject;
    sort: SortObject;
    numberOfElements: number;
    first: boolean;
    last: boolean;
    size: number;
    content: T[];
    number: number;
    empty: boolean;
}
