import { CompanyResponseDto } from '@api/company/types';
import { FileResponseDto } from '@api/file/types';
import { UserRoleResponseDto } from '@api/role/types';
import { FullUserResource, UserResponseDto } from '@api/user/types';
import { UserPermission } from '../types';

export class UserProfile implements FullUserResource {
    readonly company: CompanyResponseDto | null;
    readonly createdAt?: string | undefined;
    readonly createdBy: UserResponseDto;
    readonly email: string;
    readonly firstName: string;
    readonly id: string;
    readonly isBlocked: boolean;
    readonly isEnabled: boolean;
    readonly lastName: string;
    readonly modifiedAt?: string | undefined;
    readonly permissions: string[];
    readonly phone: string;
    readonly photo: FileResponseDto | null;
    readonly position: string;
    readonly role: UserRoleResponseDto[];

    private get allPermissions() {
        return [...this.permissions, ...(this.role?.map((role) => role.permissions).flat() ?? [])];
    }

    constructor(data: FullUserResource) {
        this.company = data.company;
        this.createdAt = data.createdAt;
        this.createdBy = data.createdBy;
        this.email = data.email;
        this.firstName = data.firstName;
        this.id = data.id;
        this.isBlocked = data.isBlocked;
        this.isEnabled = data.isEnabled;
        this.lastName = data.lastName;
        this.modifiedAt = data.modifiedAt;
        this.permissions = data.permissions;
        this.phone = data.phone;
        this.photo = data.photo;
        this.position = data.position;
        this.role = data.role;
    }

    hasPermission(permission: UserPermission | undefined | null) {
        if (permission) {
            const [group, action] = permission;
            const permStr = `${group}Controller#${action}`;

            return this.allPermissions.some((perm) => perm === permStr);
        }

        return false;
    }

    hasAnyPermission(permissions: readonly UserPermission[] | undefined | null) {
        return permissions?.some((perm) => this.hasPermission(perm)) ?? false;
    }

    hasAllPermissions(permissions: readonly UserPermission[] | undefined | null) {
        return permissions?.every((p) => this.hasPermission(p)) ?? false;
    }
}
