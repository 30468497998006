import { ChangeDetectionStrategy, Component, forwardRef } from '@angular/core';

import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { CALENDAR_BLOCKS } from './constants/calendar-blocks';
import { CalendarBlock } from './types/calendar-block';
import { CalendarChangesCallback } from './types/calendar-changes-callback';
import { CalendarDatesDto } from '@api/job-regulation/types/calendar-regulation';

@Component({
    selector: 'un-calendar',
    templateUrl: './calendar.component.html',
    styleUrls: ['./calendar.component.less'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            useExisting: forwardRef(() => CalendarComponent),
            multi: true,
        },
    ],
})
export class CalendarComponent implements ControlValueAccessor {
    onTouched?: () => void;
    onChanges?: CalendarChangesCallback;

    // eslint-disable-next-line @typescript-eslint/no-empty-function
    onChanged: CalendarChangesCallback = () => {};

    readonly blocks = CALENDAR_BLOCKS;

    dates: CalendarDatesDto[] = [];

    registerOnChange(fn: CalendarChangesCallback): void {
        this.onChanges = fn;
    }

    registerOnTouched(fn: () => void): void {
        this.onTouched = fn;
    }

    writeValue(value: CalendarDatesDto[]) {
        if (value) {
            this.dates = value;
        }
    }

    onSelectDate(month: number, days: number[]): void {
        const index = this.dates.findIndex((item) => item.month === month);

        if (!this.dates.find((item) => item.month === month)) {
            this.dates.push({ month, days });
        } else {
            this.dates[index] = { ...this.dates[index], days: [...days] };
        }

        if (days.length === 0) {
            this.dates.splice(index, 1);
        }

        this.onChanged(this.dates);
        this.onChanges?.(this.dates);
        this.writeValue(this.dates);
    }

    trackByBlockMonth(_: number, block: CalendarBlock): number {
        return block.monthIndex;
    }
}
