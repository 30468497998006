import { Injectable, OnDestroy } from '@angular/core';
import { Subject, debounceTime, startWith, takeUntil } from 'rxjs';
import { WebSocketSubject, webSocket } from 'rxjs/webSocket';

import { environment } from '@env/environment';
import { WS_MAX_RECONNECTS, WS_PONG_INTERVAL } from './consts';

type WsState = 'closed' | 'error' | 'opened';
@Injectable()
export abstract class WsServiceAbstract<InType, OutType = undefined> implements OnDestroy {
    protected connectUrl?: string;
    protected wsSubject?: WebSocketSubject<InType | OutType | 'ping' | 'pong' | undefined>;
    protected readonly unsubscribe = new Subject<void>();

    private readonly events = new Subject<InType>();
    events$ = this.events.asObservable();

    private readonly wsBase = `${window.location.origin.replace('http', 'ws')}${environment.wsBase}`;
    private reconnectQty = 0;
    private keepAlive = true;
    private stateInt: WsState = 'closed';

    protected get state() {
        return this.stateInt;
    }

    private set state(val: WsState) {
        this.stateInt = val;
    }

    protected streamKey?: string;

    constructor(private readonly options: Partial<{ debug: boolean }> = {}) {}

    protected abstract formatEvent?: (event: InType) => InType;

    ngOnDestroy(): void {
        this.keepAlive = false;
        this.unsubscribe.next();
        this.unsubscribe.complete();
        this.close();
    }

    open(): void {
        if (!this.connectUrl) {
            return;
        }

        if (this.wsSubject && !this.wsSubject?.closed) {
            this.close();
        }

        if (this.reconnectQty >= WS_MAX_RECONNECTS) {
            // eslint-disable-next-line no-console
            console.log('Max reconnects reached');
            this.keepAlive = false;

            return;
        }

        this.reconnectQty++;

        const ws = webSocket<InType | OutType | 'ping' | 'pong' | undefined>({
            url: `${this.wsBase}${this.connectUrl}`,
            deserializer: (evt: MessageEvent): InType | 'ping' | undefined => {
                try {
                    return JSON.parse(evt.data);
                } catch (e) {
                    if (evt.data === 'ping') {
                        return 'ping';
                    } else {
                        // eslint-disable-next-line no-console
                        console.error(e);

                        return undefined;
                    }
                }
            },
        });

        this.wsSubject = ws;
        this.state = 'opened';

        ws.subscribe({
            next: (evt) => {
                if (this.options?.debug) {
                    // eslint-disable-next-line no-console
                    console.log('WS event$', { evt });
                }

                if (evt === 'ping' || evt === 'pong') {
                    this.wsSubject?.next('pong');
                } else if (evt != null) {
                    const event = this.formatEvent ? this.formatEvent(evt as InType) : (evt as InType);
                    this.events.next(event);
                }
            },
            error: (err) => {
                // eslint-disable-next-line no-console
                console.warn('WS error:', err, ws);

                this.state = 'error';
                this.reconnect();
            },
            complete: () => {
                // eslint-disable-next-line no-console
                console.log('connection closed');
                ws.unsubscribe();

                if (this.keepAlive && (this.state === 'opened' || this.state === 'error')) {
                    ws.closed = true;
                    this.reconnect();
                }
            },
        });

        ws.pipe(startWith('ping'), debounceTime(WS_PONG_INTERVAL), takeUntil(this.unsubscribe)).subscribe(() => {
            ws.next('pong');
        });
    }

    close(): void {
        // eslint-disable-next-line no-console
        console.warn('closing ws');
        this.state = 'closed';

        if (this.wsSubject) {
            this.wsSubject.unsubscribe();
            this.wsSubject.complete();
            this.wsSubject.closed = true;
        }
    }

    private reconnect() {
        if (this.wsSubject) {
            this.wsSubject.unsubscribe();
            this.wsSubject.complete();
            this.wsSubject.closed = true;
        }

        setTimeout(() => {
            // eslint-disable-next-line no-console
            console.log(`reconnecting (try=${this.reconnectQty})`);

            this.open();
        });
    }
}
