import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';
import { ControlContainer, FormGroup } from '@angular/forms';
import { trackBySelectItem } from '@shared/utils/directives/track-by-select-item';
import { TIME_PERIOD_OPTIONS } from '../../constants/time-period-options';

@Component({
    selector: 'un-discrete-form',
    templateUrl: './discrete-form.component.html',
    styleUrls: ['./discrete-form.component.less'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DiscreteFormComponent implements OnInit {
    form!: FormGroup;

    readonly timePeriodOptions = TIME_PERIOD_OPTIONS;
    readonly trackBySelectItem = trackBySelectItem;

    constructor(private readonly controlContainer: ControlContainer) {}

    ngOnInit(): void {
        this.form = this.controlContainer.control as FormGroup;
    }
}
