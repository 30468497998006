import { CalendarBlock } from '../types/calendar-block';

export const CALENDAR_BLOCKS: CalendarBlock[] = [
    {
        monthName: 'Январь',
        monthIndex: 1,
        daysCount: 31,
    },
    {
        monthName: 'Февраль',
        monthIndex: 2,
        daysCount: 28,
    },
    {
        monthName: 'Март',
        monthIndex: 3,
        daysCount: 31,
    },
    {
        monthName: 'Апрель',
        monthIndex: 4,
        daysCount: 30,
    },
    {
        monthName: 'Май',
        monthIndex: 5,
        daysCount: 31,
    },
    {
        monthName: 'Июнь',
        monthIndex: 6,
        daysCount: 30,
    },
    {
        monthName: 'Июль',
        monthIndex: 7,
        daysCount: 31,
    },
    {
        monthName: 'Август',
        monthIndex: 8,
        daysCount: 31,
    },
    {
        monthName: 'Сентябрь',
        monthIndex: 9,
        daysCount: 30,
    },
    {
        monthName: 'Октябрь',
        monthIndex: 10,
        daysCount: 31,
    },
    {
        monthName: 'Ноябрь',
        monthIndex: 11,
        daysCount: 30,
    },
    {
        monthName: 'Декабрь',
        monthIndex: 12,
        daysCount: 31,
    },
];
