import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { ApiAbstract } from '@api/api-abstract';
import { Notification } from './types';

@Injectable({
    providedIn: 'root',
})
export class NotificationsApiService extends ApiAbstract {
    constructor() {
        super('job');
    }

    getNotifications(params: { userId: string }): Observable<Notification[]> {
        return this.httpClient.get<Notification[]>(`${this.base}/notification`, { params });
    }

    readNotification(id: string): Observable<void> {
        return this.httpClient.post<void>(`${this.base}/notification/read/${id}`, {});
    }

    readAllNotifications(): Observable<void> {
        return this.httpClient.post<void>(`${this.base}/notification/read/all`, {});
    }
}
