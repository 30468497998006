import { Pipe, PipeTransform } from '@angular/core';
import { getInitial } from '../utils/helper';

@Pipe({
    name: 'unInitials',
    standalone: true,
})
export class InitialsPipe implements PipeTransform {
    transform(user: { firstName: string; lastName: string } | undefined): string {
        return [getInitial(user?.firstName), getInitial(user?.lastName)].filter(Boolean).join('');
    }
}
