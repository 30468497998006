import { ChangeDetectionStrategy, Component } from '@angular/core';
import { CommonModule } from '@angular/common';
import { NzBreadCrumbModule } from 'ng-zorro-antd/breadcrumb';
import { Breadcrumb } from '@un-types/breadcrumb';
import { RouterModule } from '@angular/router';
import { BreadcrumbsService } from '@app/services/breadcrumbs.service';

@Component({
    selector: 'un-breadcrumbs',
    standalone: true,
    imports: [CommonModule, RouterModule, NzBreadCrumbModule],
    templateUrl: './breadcrumbs.component.html',
    styleUrls: ['./breadcrumbs.component.less'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class BreadcrumbsComponent {
    readonly breadcrumbs$ = this.breadcrumbsService.breadcrumbs$;

    constructor(private readonly breadcrumbsService: BreadcrumbsService) {}

    isLinkBreadcrumb(breadcrumb: Breadcrumb, isLast = false): boolean {
        return !!breadcrumb.url && !isLast;
    }

    trackBreadcrumbsByUrl(_: number, breadcrumb: Breadcrumb): string {
        return breadcrumb.url;
    }
}
