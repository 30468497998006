import { UserPermission } from '@app/core/types';

export const UserBlockCtlr = 'userBlock';
export const UserBlockPermissions = {
    create: [UserBlockCtlr, 'create'] as UserPermission,
    delete: [UserBlockCtlr, 'delete'] as UserPermission,
} as const;

export const UserCtlr = 'user';
export const UserPermissions = {
    getOne: [UserCtlr, 'getOne'] as UserPermission,
    search: [UserCtlr, 'search'] as UserPermission,
    update: [UserCtlr, 'update'] as UserPermission,
    updateSelf: [UserCtlr, 'updateSelf'] as UserPermission,
} as const;

export const UserInviteCtlr = 'userInvite';
export const UserInvitePermissions = {
    create: [UserInviteCtlr, 'create'] as UserPermission,
} as const;

export const UserMeCtlr = 'userMe';
export const UserMePermissions = {
    getCurrentUser: [UserMeCtlr, 'getCurrentUser'] as UserPermission,
} as const;

export const UserRoleCtlr = 'userRole';
export const UserRolePermissions = {
    create: [UserRoleCtlr, 'create'] as UserPermission,
    getOne: [UserRoleCtlr, 'getOne'] as UserPermission,
    search: [UserRoleCtlr, 'search'] as UserPermission,
    getCurrentUser: [UserRoleCtlr, 'getCurrentUser'] as UserPermission,
    update: [UserRoleCtlr, 'update'] as UserPermission,
} as const;
