import { FileDto } from '@api/file/types';

export const saveBlobToFile = (blob: Blob, fileInfo?: Partial<FileDto>): void => {
    const url = window.URL.createObjectURL(blob);
    saveToFile(url, fileInfo);
};

export const saveToFile = (url: string, fileInfo?: Partial<FileDto>): void => {
    const link = document.createElement('a');
    link.href = url;
    const fileName = `${fileInfo?.filename ?? 'download.file'}`;
    link.setAttribute('download', fileName);
    document.body.appendChild(link);
    link.click();
    link.remove();
};
