import { UniodStatus } from './uniod-status';

export enum UndEventLevel {
    Info = 'INFO',
    Warn = 'WARNING',
    Error = 'ERROR',
    NotUsed = 'NOT_USED',
    Unknown = 'UNKNOWN',
}

export enum UndWsEventType {
    Telemetry = 'TELEMETRY',
    Status = 'STATUS',
}

export type EventLevel = 0 | 1 | 2;

export interface UndEvent {
    deviceId: string;
    eventId: string;
    level: UndEventLevel;
    message: string;
}

export interface UndEventType {
    id: string;
    code: number;
    level: UndEventLevel;
    msg: string;
    fullMsg: string;
    srcPath: number[];
    sourceId: number;
    cond: string;
}

export interface UndFullEvent {
    uniodModelId: string;
    typeId: string;
    uniodId: string;
    uniodName: string;
    code: number;
    sourceId: number;
    sourceName: string;
    level: UndEventLevel;
    fullMsg: string;
    path: number[];
    datetime: string; // datetime ISO string
    eventData?: Record<string, string> | string;
}

export type UndFullEventEmbedded = {
    fullEvents: UndFullEvent[];
};

export interface UndFullWsEvent extends UndFullEvent {
    type: UndWsEventType.Telemetry;
}

export interface UndStatusWsEvent extends UniodStatus {
    id: string; // backward compatibility uniod UUID
    type: UndWsEventType.Status;
}

export type UniodWsEvent = UndFullWsEvent | UndStatusWsEvent;

export interface EventFilter {
    fullMsg: string;
}

export interface WsSubscribeMsg {
    subscribe?: string[];
    unsubscribe?: string[];
}
