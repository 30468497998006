import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, Input, OnInit } from '@angular/core';
import { NzButtonModule } from 'ng-zorro-antd/button';
import { NzDrawerRef } from 'ng-zorro-antd/drawer';

import { JobsApiService } from '@api/jobs/jobs-api.service';
import { CreateJobRequest, JobResource, UpdateJobRequest } from '@api/jobs/types';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { NzNotificationService } from 'ng-zorro-antd/notification';
import { NzSpinModule } from 'ng-zorro-antd/spin';
import { finalize, tap } from 'rxjs';
import { JobFormComponent } from '../job-form/job-form.component';

@UntilDestroy()
@Component({
    selector: 'un-job-drawer',
    standalone: true,
    imports: [CommonModule, JobFormComponent, NzButtonModule, NzSpinModule],
    templateUrl: './job-drawer.component.html',
    styleUrls: ['./job-drawer.component.less'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class JobDrawerComponent implements OnInit {
    @Input()
    jobId?: string;

    @Input()
    uniodId?: string;

    saving = false;
    loading = false;
    formValid = false;
    jobData?: JobResource;

    private data?: CreateJobRequest | UpdateJobRequest;

    constructor(
        private readonly jobApi: JobsApiService,
        private readonly drawerRef: NzDrawerRef<JobDrawerComponent>,
        private readonly nzNotificationService: NzNotificationService,
    ) {}

    ngOnInit(): void {
        if (this.jobId) {
            this.loading = true;

            this.jobApi
                .getJobDataById(this.jobId)
                .pipe(
                    finalize(() => {
                        this.loading = false;
                    }),
                    untilDestroyed(this),
                )
                .subscribe((data) => {
                    this.jobData = data;
                });
        }
    }

    save() {
        if (this.data) {
            this.jobApi.createJob({});
            const { checkList, startAt, deadline, ...rest } = this.data;

            const payload: UpdateJobRequest | CreateJobRequest = {
                ...rest,
                checkList: checkList?.length ? checkList : null,
                startAt: startAt ? new Date(new Date(startAt).setHours(0, 0, 0, 0)) : null,
                // eslint-disable-next-line @typescript-eslint/no-magic-numbers
                deadline: deadline ? new Date(new Date(deadline).setHours(23, 59, 59, 999)) : null,
            };

            const req = this.jobId
                ? this.jobApi
                      .updateJob(this.jobId, payload as UpdateJobRequest)
                      .pipe(tap(() => this.nzNotificationService.success('Успешно', 'Работа успешно обновлена')))
                : this.jobApi
                      .createJob(payload as CreateJobRequest)
                      .pipe(
                          tap(() => this.nzNotificationService.success('Успешно', 'Заявка на работу успешно создана')),
                      );

            this.saving = true;

            req.pipe(
                finalize(() => {
                    this.saving = false;
                }),
                untilDestroyed(this),
            ).subscribe(() => this.drawerRef.close(true));
        }
    }

    onFormChange(val: CreateJobRequest | UpdateJobRequest) {
        this.data = val;
    }

    onValidityChange(val: boolean) {
        this.formValid = val;
    }

    onCancel(): void {
        this.drawerRef.close(false);
    }
}
