<nz-select
  [nzLoading]="loading"
  [nzAllowClear]="unAllowClear"
  [nzShowSearch]="unShowSearch"
  [nzOptions]="options"
  [nzDisabled]="unDisabled"
  [nzMode]="unMode"
  [nzPlaceHolder]="unPlaceHolder"
  [nzServerSearch]="true"
  [(ngModel)]="value"
  (ngModelChange)="onChangeModel($event)"
  (nzScrollToBottom)="onScrollToBottom()"
  (nzOnSearch)="onSearch($event)"
></nz-select>
