import { Injectable } from '@angular/core';
import { Observable, share, takeUntil } from 'rxjs';

import { Notification } from '@api/notifications/types';
import { ProfileService } from '../../core/services/profile.service';
import { WsServiceAbstract } from './ws-service-abstract';

@Injectable()
export class JobsWsEventsService extends WsServiceAbstract<Notification> {
    protected override formatEvent? = undefined;

    constructor(private readonly profile: ProfileService) {
        super();
        this.connectUrl = `/api/job/notification/subscribe?token=${this.profile.token}`;
    }

    getEventsStream(): Observable<Notification> {
        return this.events$.pipe(share(), takeUntil(this.unsubscribe));
    }
}
