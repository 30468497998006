<div class="preview-block">
  <ng-template [ngIf]="!disabled" [ngIfElse]="imagePreview">
    <nz-upload nzName="avatar" [nzAccept]="imageFileAccept" [nzCustomRequest]="customUploadReq"
      [nzShowUploadList]="false">
      <div class="preview-block__upload">
        <div *ngIf="value && !disabled" class="preview-block__close" (click)="onRemove($event)">
          <span nz-icon nzType="close"></span>
        </div>

        <ng-container *ngIf="loading$$ | async; else previewBlockContent">
          <span nz-icon nzType="loading"></span>
        </ng-container>
      </div>
    </nz-upload>
  </ng-template>
</div>

<ng-template #previewBlockContent>
  <div *ngIf="!value; else imagePreview" class="preview-block__content">
    <span nz-icon nzType="icons/common:arrow-up"></span>
    Загрузить
  </div>

</ng-template>

<ng-template #imagePreview>
  <div class="preview-block__image">
    <un-image-view [imageId]="value"></un-image-view>
  </div>
</ng-template>