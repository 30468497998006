import { ChangeDetectionStrategy, Component } from '@angular/core';
import { Location } from '@angular/common';

@Component({
    selector: 'un-error-404',
    templateUrl: './error-404.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class Error404Component {
    constructor(private readonly location: Location) {}

    backClick() {
        this.location.back();
    }
}
