import { Injectable } from '@angular/core';
import { ApiAbstract } from '@api/api-abstract';
import { AppConfig } from './types';

@Injectable({ providedIn: 'root' })
export class ConfigApiService extends ApiAbstract {
    constructor() {
        super('config');
    }

    getCurrent() {
        return this.httpClient.get<AppConfig>(this.base);
    }
}
