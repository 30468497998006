<div class="form-wrapper">
  <nz-spin [nzSpinning]="loading">
    <div class="form">
      <un-job
        [jobData]="jobData"
        [uniodId]="uniodId"
        (formChange)="onFormChange($event)"
        (validityChange)="onValidityChange($event)"
      ></un-job>
    </div>
  </nz-spin>
</div>
<div class="footer">
  <button nz-button (click)="onCancel()">Отменить</button>
  <button nz-button nzType="primary" [nzLoading]="saving" [disabled]="!formValid" (click)="save()">
    {{ jobId ? 'Сохранить' : 'Создать заявку' }}
  </button>
</div>
