<div class="sidebar-logo">
  <a [routerLink]="'/'">
    <span nz-icon nzType="icons/menu:logo"></span>
  </a>
</div>
<ul>
  <ng-template ngFor let-item [ngForOf]="items | async" [ngForTrackBy]="trackBy">
    <li>
      <ng-template [ngIf]="isExternal(item.path)" [ngIfElse]="internal">
        <a [href]="item.path" target="_blank">
          <span nz-icon [nzType]="item.icon"></span>
        </a>
      </ng-template>
      <ng-template #internal>
        <a routerLinkActive="active" [routerLink]="['/', item.path]">
          <span nz-icon [nzType]="item.icon"></span>
        </a>
      </ng-template>
    </li>
  </ng-template>
</ul>
<span class="version" nz-tooltip nzTooltipTrigger="click" [nzTooltipTitle]="version">{{
  isCollapsed ? 'Вер.' : version
}}</span>
