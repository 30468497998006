import { Injectable } from '@angular/core';
import {
    Observable,
    catchError,
    concatWith,
    distinctUntilChanged,
    filter,
    from,
    map,
    of,
    share,
    switchMap,
    takeUntil,
    tap,
} from 'rxjs';

import { EventLevel, UndStatusWsEvent, UndWsEventType, UniodWsEvent } from '@api/uniod/types/events';
import { ProfileService } from '../../core/services/profile.service';
import { WsServiceAbstract } from './ws-service-abstract';

@Injectable()
export class UniodWsEventsService extends WsServiceAbstract<UniodWsEvent> {
    protected formatEvent = (evt: UniodWsEvent): UniodWsEvent =>
        'uniodStatus' in evt ? (evt.uniodStatus as UndStatusWsEvent) : evt;

    constructor(private readonly profile: ProfileService) {
        super();
    }

    initConnection(uniodId: string | null, level: EventLevel = 2): void {
        this.connectUrl = `/api/uniod/${uniodId}/subscribe?level=${level}&token=${this.profile.token}`;
        this.streamKey = uniodId ?? undefined;
    }

    getEventsStream<T>(
        uniodId$: Observable<string | null>,
        actualApi: (id: string) => Observable<T[]>,
        eventTypes?: UndWsEventType[],
    ): Observable<T> {
        return uniodId$.pipe(
            filter((v) => v != null),
            distinctUntilChanged(),
            tap((uniodId) => {
                if (uniodId !== this.streamKey) {
                    this.initConnection(uniodId);
                }

                if (this.state !== 'opened') {
                    this.open();
                }
            }),
            switchMap((uniodId) =>
                actualApi(uniodId as string).pipe(
                    catchError(() => of([])),
                    switchMap((events) => from(events)),
                    concatWith(
                        this.events$.pipe(
                            filter((evt) => !eventTypes || eventTypes.includes(evt.type)),
                            map((evt) => evt as T),
                        ),
                    ),
                ),
            ),
            share(),
            takeUntil(this.unsubscribe),
        );
    }
}
