import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';

@Injectable()
export class ResizableSidebarService {
    private readonly resizable$$ = new BehaviorSubject<boolean>(false);
    private readonly triggerChangeWidth$$ = new BehaviorSubject<string>('0');

    readonly resizable$: Observable<boolean> = this.resizable$$.asObservable();
    readonly triggerChangeWidth$: Observable<string> = this.triggerChangeWidth$$.asObservable();

    setResizable(isResizable: boolean): void {
        this.resizable$$.next(isResizable);
    }

    setLocalWidth(width: string): void {
        localStorage.setItem('sidebar', width);
        this.triggerChangeWidth$$.next(width);
    }

    collapseSidebar(): void {
        localStorage.setItem('sidebar', '0');
        this.triggerChangeWidth$$.next('0');
    }

    expandSidebar(): void {
        localStorage.setItem('sidebar', '270px');
        this.triggerChangeWidth$$.next('270px');
    }

    getLocalWidth(): string {
        return localStorage.getItem('sidebar') ?? '270px';
    }
}
