import { ChangeDetectionStrategy, ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { BehaviorSubject } from 'rxjs';

import { Notification, NotificationType } from '@api/notifications/types';
import { NotificationsService } from '@app/layout/services/notifications.service';
import { JobDrawerComponent } from '@app/shared/components/job/job-drawer/job-drawer.component';
import { trackById } from '@shared/utils/directives/track-by-id';
import { NzDrawerService } from 'ng-zorro-antd/drawer';

@UntilDestroy()
@Component({
    selector: 'un-notifications',
    templateUrl: './notifications.component.html',
    styleUrls: ['./notifications.component.less'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class NotificationsComponent implements OnInit {
    loading$ = new BehaviorSubject<boolean>(false);

    notifications: Notification[] = [];

    readonly trackById = trackById;
    readonly notificationType = NotificationType;

    constructor(
        private readonly notificationsService: NotificationsService,
        private readonly drawerService: NzDrawerService,
        private readonly cdr: ChangeDetectorRef,
    ) {}

    ngOnInit(): void {
        this.notificationsService.needUpdateList$.pipe(untilDestroyed(this)).subscribe(() => this.getNotifications());
    }

    onClick(id: string, jobId: string): void {
        this.notificationsService
            .readNotification(id)
            .pipe(untilDestroyed(this))
            .subscribe((_) => {
                this.showJob(jobId);
                this.notificationsService.needUpdateList();
            });
    }

    readAll(): void {
        this.notificationsService
            .readAllNotifications()
            .pipe(untilDestroyed(this))
            .subscribe(() => this.notificationsService.needUpdateList());
    }

    getNotifications(): void {
        this.loading$.next(true);

        this.notificationsService
            .getNotifications()
            .pipe(untilDestroyed(this))
            .subscribe((val) => {
                this.notifications = val;
                this.loading$.next(false);
                this.cdr.markForCheck();
            });
    }

    private showJob(jobId: string): void {
        this.drawerService.create({
            nzContent: JobDrawerComponent,
            nzContentParams: { jobId },
            nzWidth: 'fit-content',
            nzClosable: false,
            nzTitle: 'Заявка на работу',
        });
    }
}
