import { registerLocaleData } from '@angular/common';
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import ru from '@angular/common/locales/ru';
import { ru as RU } from 'date-fns/locale';
import { LOCALE_ID, NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { RouterModule, provideRouter } from '@angular/router';
import { NzDrawerModule } from 'ng-zorro-antd/drawer';
import { NZ_I18N, ru_RU, NZ_DATE_LOCALE } from 'ng-zorro-antd/i18n';
import { NzLayoutModule } from 'ng-zorro-antd/layout';
import { NzMenuModule } from 'ng-zorro-antd/menu';
import { NzNotificationModule } from 'ng-zorro-antd/notification';

import { LayoutModule } from '@layout/layout.module';
import { APP_MODULE_ROUTES } from './app-module-routes';
import { AppComponent } from './app.component';
import { AuthInterceptor } from './core/interceptors/auth.interceptor';
import { ErrorInterceptor } from './core/interceptors/error.interceptor';
import { IconsProviderModule } from './icons-provider.module';
import { UniodWsEventsService } from './services/ws/uniod-ws-events.service';
import { NzConfig, provideNzConfig } from 'ng-zorro-antd/core/config';

const ngZorroConfig: NzConfig = {
    notification: { nzMaxStack: 5 },
};

registerLocaleData(ru);

@NgModule({
    declarations: [AppComponent],
    imports: [
        BrowserModule,
        RouterModule,
        LayoutModule,
        FormsModule,
        HttpClientModule,
        BrowserAnimationsModule,
        IconsProviderModule,
        NzDrawerModule,
        NzLayoutModule,
        NzMenuModule,
        NzNotificationModule,
    ],
    bootstrap: [AppComponent],
    providers: [
        {
            provide: NZ_I18N,
            useValue: ru_RU,
        },
        {
            provide: LOCALE_ID,
            useValue: 'ru_RU',
        },
        {
            provide: NZ_DATE_LOCALE,
            useValue: RU,
        },
        {
            provide: HTTP_INTERCEPTORS,
            useClass: AuthInterceptor,
            multi: true,
        },
        {
            provide: HTTP_INTERCEPTORS,
            useClass: ErrorInterceptor,
            multi: true,
        },
        UniodWsEventsService,
        provideNzConfig(ngZorroConfig),
        provideRouter(APP_MODULE_ROUTES),
    ],
})
export class AppModule {}
