import { Injectable } from '@angular/core';

import { ApiAbstract } from '@api/api-abstract';
import { Page, SearchParams } from '@api/types';
import { JobStatusResource, SearchJobStatusRequest } from './types';

@Injectable({ providedIn: 'root' })
export class JobStatusApiService extends ApiAbstract {
    constructor() {
        super('job/status');
    }

    getStatusList(search: SearchJobStatusRequest = {}, pageParams?: SearchParams) {
        return this.httpClient.post<Page<JobStatusResource>>(`${this.base}/search`, search, { params: pageParams });
    }

    getNextStatuses(statusId: string) {
        return this.httpClient.get<JobStatusResource[]>(`${this.base}/${statusId}/matrix`);
    }
}
