import { NzTableSortOrder } from 'ng-zorro-antd/table';

import { GroupData } from '@api/uniod-groups/types';
import { SelectOption } from '@app/types/common';
import { CustomColumn } from '@app/types/table-settings';

export const trackOptions = <T>(index: number, item: SelectOption<T>): T => item.value;

export const getInitial = (name: string | null | undefined): string => (name ? `${name.slice(0, 1)}.` : '');

export const getShortLastname = (
    user: { firstName: string | null; lastName: string | null } | undefined | null,
): string => [user?.firstName ?? '', getInitial(user?.lastName)].filter(Boolean).join(' ');

export const getShortFirstName = (
    user: { firstName: string | null; lastName: string | null } | undefined | null,
): string => [user?.lastName ?? '', getInitial(user?.firstName)].filter(Boolean).join(' ');

export const groupsToString = (groups: GroupData[]): string =>
    groups.length ? groups.map((item) => item.name).toString() : '-';

export const getShortName = (name: string, length: number): string =>
    name.length >= length ? `${name.slice(0, length)}...` : name;

export const convertSortOrderForApi = (sortOrder: string | null): string | null => {
    switch (sortOrder) {
        case 'ascend':
            return 'asc';
        case 'descend':
            return 'desc';
        default:
            return null;
    }
};

export const toggleSorting = <T extends { id: string; sortOrder?: NzTableSortOrder | null }>(
    column: T,
    listOfColumns: T[],
): T[] =>
    listOfColumns.map((item) => ({
        ...item,
        sortOrder: item.id === column.id ? (item.sortOrder === 'ascend' ? 'descend' : 'ascend') : null,
    }));

export const SettingsColName = 'settings';

export const mergeTablesWithSettings = (defaultSettings: CustomColumn[], stored: CustomColumn[]): CustomColumn[] => {
    const res = stored
        .filter((col) => col.id !== SettingsColName && defaultSettings.some(({ id }) => id === col.id))
        .map((col) => defaultSettings.find((item) => item.id === col.id) ?? col);

    let settingsCol: CustomColumn | null = null;

    defaultSettings.forEach((col, idx) => {
        if (col.id !== SettingsColName) {
            if (!stored.some(({ id }) => id === col.id)) {
                res.splice(idx, 0, col);
            }
        } else {
            settingsCol = col;
        }
    });

    if (settingsCol) {
        res.push(settingsCol);
    }

    return res;
};

export const getTwoConds = (cond: string | undefined | null) => {
    let cond1 = cond?.split('$val')[0];
    let cond2 = cond?.split('$val')[1];
    // eslint-disable-next-line @typescript-eslint/no-magic-numbers
    const minValue = cond1?.substring(0, cond1.length - (cond1.includes('=') ? 2 : 1));
    // eslint-disable-next-line @typescript-eslint/no-magic-numbers
    cond1 = cond1?.substring(cond1.length - (cond1.includes('=') ? 2 : 1));
    // eslint-disable-next-line @typescript-eslint/no-magic-numbers
    const maxValue = cond2?.substring(cond2?.includes('=') ? 2 : 1);
    // eslint-disable-next-line @typescript-eslint/no-magic-numbers
    cond2 = cond2?.substring(0, cond2?.includes('=') ? 2 : 1);

    return { cond1, minValue, cond2, maxValue };
};
