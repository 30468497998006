import { AppVersion } from 'src/version';
import { Environment } from './environment.interface';

export const environment: Environment = {
    apiBase: '/api',
    wsBase: '/ws',
    grafanaBase: '/grafana',
    supersetBase: `${location.protocol}//${location.hostname}:8089`,
    supersetDemoBase: 'https://superset.ntrlab.ru',
    production: false,
    version: `${AppVersion}-dev`,
    attr3dName: '3DModel',
    attrGrafana: 'GRAFANA_DASHBOARD',
    attrSuperset: 'SUPERSET_DASHBOARD',
};
