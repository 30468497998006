import { Directive, HostBinding, Input } from '@angular/core';
import { ResizableSidebarService } from '@app/services/resizable-sidebar/resizable-sidebar.service';
import { isString } from 'lodash-es';

@Directive()
export class Sidebar {
    @Input()
    set unWidth(value: string | number) {
        this.sidebarWidth = isString(value) ? value : `${value}px`;
    }

    @Input()
    set resizable(value: boolean) {
        this.resizableSidebarService.setResizable(value);
    }

    constructor(private readonly resizableSidebarService: ResizableSidebarService) {}

    @HostBinding('style.width')
    sidebarWidth = '100%';
}
