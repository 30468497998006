import { Injectable } from '@angular/core';
import { Observable, map } from 'rxjs';

import { FileApiService } from '@api/file/file-api.service';
import { FileInfoWithUrl } from '@api/file/types';
import { ProfileService } from '@app/core/services/profile.service';
import { environment } from '@env/environment';

@Injectable({
    providedIn: 'root',
})
export class FileService {
    private readonly apiUrl = `${environment.apiBase}/app/v3`;

    constructor(private readonly profile: ProfileService, private readonly fileApi: FileApiService) {}

    getFileUrl(fileId: string): string {
        return `${this.apiUrl}/file/${fileId}?token=${this.profile.token}`;
    }

    getFileById(fileId: string): Observable<Blob> {
        return this.fileApi.getFileById(fileId, this.profile.token);
    }

    getFilesInfoWithUrls(fileIds: string[]): Observable<FileInfoWithUrl[]> {
        return this.fileApi
            .getFileInfo(fileIds)
            .pipe(map((data) => data.map((info) => ({ ...info, url: this.getFileUrl(info.id) } as FileInfoWithUrl))));
    }

    upload(file: Blob, filename: string) {
        return this.fileApi.upload(file, filename);
    }
}
