import { DatePipe } from '@angular/common';
import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';
import { NzAvatarModule } from 'ng-zorro-antd/avatar';
import { NzIconModule } from 'ng-zorro-antd/icon';

import { JobCommentResource } from '@api/job-comment/types';
import { InitialsPipe } from '../../../pipes/initials.pipe';

@Component({
    selector: 'un-job-comment',
    templateUrl: './job-comment.component.html',
    styleUrls: ['./job-comment.component.less'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: true,
    imports: [NzAvatarModule, InitialsPipe, DatePipe, NzIconModule],
})
export class JobCommentComponent {
    @Input()
    jobId?: string;

    @Input()
    comment!: JobCommentResource;

    @Output()
    deleteComment = new EventEmitter();

    onDelete(id: string): void {
        this.deleteComment.emit(id);
    }
}
